import React from "react";
import styled, { css } from "styled-components";
import Logo from "./Logo";
import { Colors, Typography } from "../atoms/Theme";
import { Link } from "@reach/router";
import Column from "../atoms/Column";
import {
  FaCaretDown,
  FaCaretRight,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { DataContext } from "../../providers/DataProvider";
import { AuthContext } from "providers/AuthProvider";
import IconButton from "components/atoms/IconButton";
import useResponsive from "hooks/useResponsive";

const SidebarContext = React.createContext({});

const Container = styled.div`
  z-index: 10;
  height: 100vh;
  position: sticky;
  top: 0;
`;

const SidebarContainer = styled(Column)`
  position: relative;
  z-index: 5;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  background: ${Colors.red};
  border-radius: 0px 30px 30px 0px;
  justify-content: space-between;
  padding: 29px 12px;
  width: 220px;
  transition: width 0.5s ease;
  ${(props) =>
    !props.show &&
    css`
      width: 0;
      overflow-x: hidden;
      padding: 0;
    `}
`;

const SidebarSection = styled(Column)``;

// Nav Stuff
const NavItemContainer = styled.div`
  background: ${Colors.red};
  border-radius: 16px;
  padding: 4px 16px;
  padding-right: 0;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
`;

const NavItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightCaret = styled(FaCaretRight)`
  cursor: pointer;
  color: white;
`;

const DownCaret = styled(FaCaretDown)`
  cursor: pointer;
  color: white;
`;

const IconContainer = styled.div`
  display: flex;
`;

const NavItemTitle = styled(Link)`
  ${Typography.regular}
  display: flex;
  cursor: pointer;
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const NavItemContentContainer = styled.div`
  margin-top: 4px;
  padding-left: 30px;
`;

const NavItem = ({ title, to, children }) => {
  const { handleCloseSidebar } = React.useContext(SidebarContext);
  const [showContent, setShowContent] = React.useState(false);

  return (
    <NavItemContainer>
      <NavItemTitleContainer>
        {children !== undefined && (
          <IconContainer onClick={() => setShowContent(!showContent)}>
            {showContent ? <DownCaret size={25} /> : <RightCaret size={25} />}
          </IconContainer>
        )}
        <NavItemTitle
          to={to}
          style={{ margin: 0 }}
          onClick={handleCloseSidebar}
        >
          {title}
        </NavItemTitle>
      </NavItemTitleContainer>
      {showContent && (
        <NavItemContentContainer>{children}</NavItemContentContainer>
      )}
    </NavItemContainer>
  );
};

const ShowSidebarContainer = styled.div`
  position: absolute;
  left: 100%;
  bottom: 50px;
  background: ${Colors.red};
  border-radius: 0 20px 20px 0;
  width: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.2s ease;
  &:hover {
    width: 35px;
  }
`;

const Sidebar = () => {
  const { isMobile, isDesktop } = useResponsive();
  const { signedIn } = React.useContext(AuthContext);
  const { seller, vendor } = React.useContext(DataContext);
  const [showSidebar, setShowSidebar] = React.useState(isDesktop);

  const handleCloseSidebar = React.useCallback(() => {
    if (isMobile) setShowSidebar(false);
  }, [isMobile]);

  if (
    !signedIn ||
    (seller && (!seller.StripeAccountCompleted || !seller.Onboarded))
  )
    return <Container />;

  return (
    <SidebarContext.Provider value={{ handleCloseSidebar }}>
      <Container>
        <ShowSidebarContainer>
          <IconButton
            size={16}
            font={14}
            icon={showSidebar ? FaChevronLeft : FaChevronRight}
            onClick={() => setShowSidebar((show) => !show)}
          />
        </ShowSidebarContainer>
        <SidebarContainer show={showSidebar}>
          <SidebarSection>
            <Link
              to="/"
              style={{ alignSelf: "center" }}
              onClick={handleCloseSidebar}
            >
              <Logo />
            </Link>

            <NavItem title={vendor.Name} to="/me" />
            <NavItem title="Orders" to="/orders" />
            <NavItem title="Markets" to="/markets">
              {vendor.MarketArray.map((market) => (
                <NavItemTitle
                  key={market.MarketID}
                  to={`/markets/edit/${market.MarketID}`}
                >
                  {market.Name}
                </NavItemTitle>
              ))}
            </NavItem>
            <NavItem title="Products" to="/products">
              {vendor.ProductArray.map((product) => (
                <NavItemTitle
                  key={product.ProductID}
                  to={`/products/edit/${product.ProductID}`}
                  state={{ product }}
                >
                  {product.Name}
                </NavItemTitle>
              ))}
            </NavItem>
            <NavItem title="Rewards" to="/rewards">
              {vendor.CouponArray.map((coupon) => (
                <NavItemTitle
                  key={coupon.CouponID}
                  to={`/rewards/edit/${coupon.CouponID}`}
                  state={{ coupon }}
                >
                  {coupon.product
                    ? coupon.product.Name
                    : coupon.category.TagText}
                </NavItemTitle>
              ))}
            </NavItem>
            <NavItem title="Scan order" to="/pickup" />
            <NavItem title="Give reward points" to="/onsite" />
          </SidebarSection>
        </SidebarContainer>
      </Container>
    </SidebarContext.Provider>
  );
};

export default Sidebar;
