import { css } from "styled-components";

const Colors = {
  red: "#FF5757",
  darkRed: "#CF4444",
  lightRed: "rgba(255, 101, 101, 0.75)",
  snow: "#F9F9F9",
  green: "#63C053",
  purple: "#5C2BE3",
  gray: "#C4C4C4",
  hoveredGray: "#F0F0F0",
  clickedGray: "#E0E0E0",
  textLight: "#A3A3A3",
};

const Typography = {
  heading: css`
    font-size: 30px;
  `,
  subheading: css`
    font-size: 24px;
  `,
  label: css`
    font-size: 20px;
  `,
  regular: css`
    font-size: 16px;
  `,
  small: css`
    font-size: 14px;
  `,
};

const size = {
  mobile: "320px",
  desktop: "1024px",
};

const device = {
  mobile: `(min-width: ${size.mobile})`,
  desktop: `(min-width: ${size.desktop})`,
};

export { Colors, Typography, size, device };
