import React from "react";
import styled from "styled-components";
import Input from "components/atoms/Input";
import withControl from "utils/withControl";
import { HexColorPicker } from "react-colorful";
import debounce from "lodash.debounce";

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.color || "transparent"};
  padding: 10px;
  border-radius: 30px;
`;

const StyledColorPicker = styled(HexColorPicker)`
  width: 130px !important;
  height: 130px !important;
  margin-bottom: 10px;
`;

const ColorPicker = ({ value, onChange, hideInput }) => {
  const [color, setColor] = React.useState();

  React.useEffect(() => {
    if (!color) setColor(value);
  }, [color, value]);

  const debouncedOnChange = debounce(onChange, 500);

  const handleChange = (val) => {
    setColor(val);
    debouncedOnChange(val);
  };

  return (
    <ColorPickerContainer color={color}>
      <StyledColorPicker color={color} onChange={handleChange} />
      {!hideInput && (
        <Input value={color} onChange={handleChange} width="50%" />
      )}
    </ColorPickerContainer>
  );
};

export default ColorPicker;

export const ControlledColorPicker = withControl(ColorPicker);
