import React from "react";
import PageContainer from "../components/templates/PageContainer";
import QrReader from "react-qr-reader";
import BoxContainer from "components/atoms/BoxContainer";
import styled from "styled-components";
import { Colors, Typography } from "components/atoms/Theme";
import Space from "components/atoms/Space";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { DataContext } from "providers/DataProvider";
import { ToastContext } from "providers/ToastProvider";
import Column from "components/atoms/Column";
import Table from "components/atoms/Table";
import { formatDollars, resolveTagText } from "utils/helpers";
import Row from "components/atoms/Row";
import { completeOrder } from "services";

const ContentContainer = styled.div`
  max-width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const Heading = styled.div`
  ${Typography.heading}
  text-align: center;
`;

const Subheading = styled.div`
  ${Typography.subheading}
`;

const OrderSummaryContainer = styled(Column)`
  align-items: flex-end;
  align-self: flex-end;
`;

const OrderSummaryRow = styled(Row)``;

const Red = styled.span`
  color: ${Colors.red};
`;

const Green = styled.span`
  color: ${Colors.green};
`;

const OrdersCard = ({ orders, handleDone }) => {
  const { categoryTags, unitTags, vendor } = React.useContext(DataContext);
  const [subtotal, setSubtotal] = React.useState(0);
  const [discounts, setDiscounts] = React.useState(0);
  const [taxesAndFees, setTaxesAndFees] = React.useState(0);
  const [rewardPoints, setRewardPoints] = React.useState(0);

  const data = React.useMemo(
    () =>
      orders.map((order) => {
        setSubtotal((total) => total + (order.Cost || 0) / 100);
        setDiscounts((total) => total + (order.Discount || 0) / 100);
        setTaxesAndFees((total) => total + (order.Tax || 0) / 100);
        setRewardPoints((total) => total + (order.PointGain || 0));
        const product = vendor.ProductArray.find(
          (product) => product.ProductID === order.ProductID
        );
        return {
          ...order,
          quantity: `${order.Quantity} ${resolveTagText(
            unitTags,
            product.PriceUnitID
          )}`,
          category: resolveTagText(categoryTags, product.CategoryID),
          unitPrice: `${formatDollars(
            product.PriceMap[order.MarketID] / 100
          )}/${resolveTagText(unitTags, product.PriceUnitID)}`,
        };
      }),
    [orders, categoryTags, vendor.ProductArray, unitTags]
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Product name",
        accessor: "ProductName",
        columnWidth: "40%",
      },
      {
        Header: "Category",
        accessor: "category",
        columnWidth: "20%",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        columnWidth: "10%",
      },
      {
        Header: "Unit price",
        accessor: "unitPrice",
        columnWidth: "12%",
      },
      {
        Header: "Subtotal",
        accessor: "Cost",
        columnWidth: "12%",
        Cell: ({ value }) => formatDollars(value / 100),
      },
      {
        Header: "Discounts",
        accessor: "Discount",
        Cell: ({ value }) => formatDollars(value / 100),
        columnWidth: "12%",
      },
      {
        Header: "Type",
        accessor: "IsDelivery",
        Cell: ({ value }) => (value ? "Delivery" : "Pickup"),
        columnWidth: "10%",
      },
    ],
    []
  );

  return (
    <>
      <Heading>
        <Green>
          {orders[0].CustomerName}'s Order (#{orders[0].OrderNumber})
        </Green>
      </Heading>
      <Table data={data} columns={columns} searchable={false} width="70vw" />
      <Space height={20} />
      <OrderSummaryContainer>
        <Subheading>Order Summary:</Subheading>
        <OrderSummaryRow>Subtotal: {formatDollars(subtotal)}</OrderSummaryRow>
        {discounts > 0 && (
          <OrderSummaryRow>
            Discounts: -{formatDollars(discounts)}
          </OrderSummaryRow>
        )}
        <OrderSummaryRow>
          Taxes and fees: {formatDollars(taxesAndFees)}
        </OrderSummaryRow>
        <OrderSummaryRow>
          Total: {formatDollars(subtotal - discounts + taxesAndFees)}
        </OrderSummaryRow>
        {rewardPoints > 0 && (
          <>
            <Space height={10} />
            <OrderSummaryRow gap={3}>
              <span>{orders[0].CustomerName} earned</span>{" "}
              <Red>{rewardPoints}</Red>
              <span>reward points</span>
            </OrderSummaryRow>
          </>
        )}
      </OrderSummaryContainer>
      <Space height={20} />
      <Button onClick={handleDone}>done!</Button>
    </>
  );
};

const Pickup = ({ paramOrderNumber, navigate }) => {
  const { showToast } = React.useContext(ToastContext);
  const { refetchPendingOrders, allPendingOrders } =
    React.useContext(DataContext);
  const [orders, setOrders] = React.useState();
  const [orderNumber, setOrderNumber] = React.useState();

  const handleError = () =>
    showToast({
      message: "Please allow access to the camera.",
      type: "error",
    });

  const handleScan = React.useCallback(
    (data) => {
      if (data) {
        if (data.startsWith("http")) {
          // Received a url -> parse order num
          const splitUrl = data.split("/");
          data = splitUrl[splitUrl.length - 1];
        }
        const orderNum = parseInt(data);
        const orders = allPendingOrders.filter(
          (order) => order.OrderNumber === orderNum
        );
        if (orders.length === 0) {
          showToast({
            message: `Order #${orderNum} not found!`,
            type: "error",
          });
          return;
        }
        setOrders(orders);
      }
    },
    [allPendingOrders, showToast]
  );

  const handleDone = () => {
    completeOrder(orders[0].BasketID).then((response) => {
      if (response.ok) {
        showToast({
          message: "Order completed!",
          type: "success",
        });
        refetchPendingOrders();
        navigate("/pickup"); // clears order number in URL param (if any)
      } else {
        showToast({
          message: `Something went wrong completing the order: ${response.body.message}`,
          type: "error",
        });
      }
      setOrderNumber();
      setOrders();
    });
  };

  React.useEffect(() => {
    if (paramOrderNumber) handleScan(paramOrderNumber);
  }, [paramOrderNumber, handleScan]);

  return (
    <PageContainer heading="Scan pickup order">
      <BoxContainer width="1086px">
        <ContentContainer align="center">
          {orders && orders.length > 0 ? (
            <OrdersCard orders={orders} handleDone={handleDone} />
          ) : (
            <>
              {/* Scan order  */}
              <Heading>Scan an order QR code</Heading>
              <Space height={20} />
              <QrReader
                delay={500}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "90%" }}
              />
              <Space height={20} />
              <Subheading>or</Subheading>
              <Space height={20} />
              <Heading>Enter an order number</Heading>
              <Space height={20} />
              <Column>
                <Input value={orderNumber} onChange={setOrderNumber} />
                <Space height={20} />
                <Button onClick={() => handleScan(orderNumber)}>go!</Button>
              </Column>
            </>
          )}
        </ContentContainer>
      </BoxContainer>
    </PageContainer>
  );
};

export default Pickup;
