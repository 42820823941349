import IconButton from "components/atoms/IconButton";
import { FaTimes } from "react-icons/fa";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Colors, Typography } from "../components/atoms/Theme";

const fadeIn = keyframes` 
  from {right: 0; opacity: 0;}
  to {right: 10px; opacity: 1;}
`;

const Toast = styled.div`
  ${Typography.regular}
  position: fixed;
  top: 20px;
  right: 14px;
  padding: 12px;
  background: ${(props) =>
    props.type === "success" ? Colors.green : Colors.red};
  border-radius: 20px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.12);
  height: fit-content;
  max-width: 250px;
  animation: ${fadeIn} 0.6s ease-in;
  z-index: 1000;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  left: -12px;
  top: -12px;
`;

export const ToastContext = React.createContext({});

/**
 * Context Values:
 *   showToast [function] - call this function to set a toast
 *
 *      - toast props:
 *          - message [string]: message text
 *          - type ['success'|'error']: type of toast (changes the background color)
 *          - timeout [number]: integer represent how many milliseconds to dismiss toast after. Default: 5000
 *
 *       EX: showToast({ message: "Successfully updated!", type: "success", timeout: 2000});
 */

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = React.useState();

  const dismissToast = React.useCallback(() => setToast(), [setToast]);

  const showToast = React.useCallback(
    ({ message, type, timeout = 5000 }) => {
      setToast({ message, type });
      setTimeout(dismissToast, timeout);
    },
    [dismissToast]
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Toast type={toast.type}>
          {toast.message}
          <CloseButton icon={FaTimes} size={20} onClick={dismissToast} />
        </Toast>
      )}
    </ToastContext.Provider>
  );
};
