import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import { ControlledInput } from "../components/atoms/Input";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm } from "react-hook-form";
import CognitoClient from "../utils/CognitoClient";
import { AuthContext } from "providers/AuthProvider";
import { ToastContext } from "providers/ToastProvider";
import { FORM_LIMITS } from "utils/constants";
import styled from "styled-components";
import Logo from "components/molecules/Logo";
import Row from "components/atoms/Row";
import Space from "components/atoms/Space";
import useKeyPress from "hooks/useKeyPress";
import Link from "components/atoms/Link";

const Container = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 5%;
`;

const SignIn = ({ navigate }) => {
  const { loadUser } = React.useContext(AuthContext);
  const { showToast } = React.useContext(ToastContext);
  const { control, handleSubmit, watch } = useForm();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (data) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      await CognitoClient.signIn(data);
      loadUser(true);
      setDisabled(false);
      navigate("/");
    } catch (error) {
      if (error.name === CognitoClient.UserNotConfirmedException) {
        setDisabled(false);
        navigate("/confirm-email", { state: { ...data } });
      }
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  const onError = (errors) => {
    console.log(
      Object.entries(errors).map(
        (pair) => pair[0] + " failed the test " + pair[1].type
      )
    );
    showToast({
      message: "Sign in details don't match registration requirements.",
      type: "error",
    });
  };

  useKeyPress("Enter", { onKeyPress: handleSubmit(onSubmit, onError) });

  return (
    <Container>
      <BoxContainer>
        <Form.Container>
          <Form.Content>
            <Row justify="center">
              <Logo width="300px" />
            </Row>
            <Form.Heading>Sign in</Form.Heading>
            <Form.InputGroup>
              <Form.Label>Email</Form.Label>
              <ControlledInput
                name="email"
                control={control}
                width="100%"
                rules={{
                  required: true,
                  maxLength: FORM_LIMITS.email,
                  validate: { isEmail: CognitoClient.isEmailValid },
                }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Password</Form.Label>
              <ControlledInput
                name="password"
                type="password"
                control={control}
                width="100%"
                rules={{
                  required: true,
                  validate: { passwordStrength: CognitoClient.isPasswordValid },
                }}
              />
            </Form.InputGroup>
            <Form.Actions>
              <Column>
                <Row justify="center">
                  <Button
                    width="50%"
                    disabled={disabled}
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    sign in
                  </Button>
                </Row>
                <Space height={20} />
                <Row gap={50}>
                  <div>
                    Don't have an account?{" "}
                    <Link to="/signup">Create one here.</Link>
                  </div>
                  <Link to="/reset-password" state={{ email: watch("email") }}>
                    Forgot your password?
                  </Link>
                </Row>
              </Column>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </Container>
  );
};

export default SignIn;
