import BoxContainer from "../atoms/BoxContainer";
import DefaultButton from "../atoms/Button";
import Row from "../atoms/Row";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";
import { Typography } from "../atoms/Theme";

const ModalBackground = styled(Row)`
  align-items: center;
  justify-content: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  z-index: 5;
`;

const ModalContainer = styled(BoxContainer)`
  padding: 20px;
  width: 500px;
`;

const ModalTitle = styled.span`
  ${Typography.title}
  text-align: center;
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  ${Typography.regular}
  font-weight: 400;
`;

const ModalActions = styled(Row)`
  margin-top: 20px;
  justify-content: space-around;
`;

const Button = styled(DefaultButton).attrs()`
  flex: 1;
  margin: 0 5px;
`;

/*
 * Props:
 *      - title [string] - modal title
 *      - buttons? [Object[]] - props for Buttons to show
 *      - visible [boolean] - if modal should show
 *      - setVisible [function] - function to set visible state
 *      - children? [ReactElement | string] - modal content
 */

const Modal = ({ title, buttons, visible, setVisible, children }) => {
  if (visible)
    return (
      <ModalBackground>
        <ClickAwayListener onClickAway={() => setVisible(false)}>
          <ModalContainer>
            <ModalTitle>{title}</ModalTitle>
            <ModalContent>{children}</ModalContent>
            <ModalActions>
              {buttons.map((props) => (
                <Button {...props} key={props.children} />
              ))}
            </ModalActions>
          </ModalContainer>
        </ClickAwayListener>
      </ModalBackground>
    );
  return null;
};

export default Modal;
