import React from "react";
import styled, { css } from "styled-components";
import { Colors } from "./Theme";

const Table = styled.table`
  width: 100%;
`;

const TableBody = styled.tbody``;

const TableHeader = styled.th`
  padding: 4px;
`;

const TableRow = styled.tr`
  ${(props) =>
    props.onClick &&
    css`
      &:hover {
        background: ${Colors.hoveredGray};
        cursor: pointer;
      }
    `}
`;

const TableHead = styled.thead`
  text-align: left;
  ${TableRow} {
    background: unset;
    cursor: unset;
  }
`;

const TableCell = styled.td`
  padding: 4px;
  border-radius: 10px;
`;

const NoDataText = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const SimpleTable = ({ data, columns }) => {
  if (data.length === 0) return <NoDataText>Nothing here yet.</NoDataText>;
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(({ Header }) => (
            <TableHeader key={Header}>{Header}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((data, index) => (
          <TableRow onClick={data.onClick} key={index}>
            {columns.map(({ accessor, Cell }) => (
              <TableCell key={accessor}>
                {Cell ? Cell({value: data[accessor], row: data}) : data[accessor]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SimpleTable;
