import styled from "styled-components";
import Row from "../atoms/Row";
import Column from "../atoms/Column";
import { BaseButton } from "../atoms/Button";
import { Typography } from "../atoms/Theme";

const Container = styled.div``;

const Heading = styled.span`
  ${Typography.heading}
  text-align:center;
  margin-bottom: 10px;
`;

const Label = styled.span`
  ${Typography.label}
  line-height: 36px;
  margin-bottom: 10px;
`;

const GrayLabel = styled(Label)`
  color: gray;
  font-weight: normal;
`;

const InfoText = styled(Label)`
  font-weight: normal;
`;

const Text = styled.span`
  ${Typography.regular}
  font-weight: 400;
  margin: 0 15px;
`;

const Content = styled(Column)`
  background: #ffffff;
  border-radius: 30px 30px 0px 0px;
  padding: 30px;
`;

const SpacedRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Actions = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  ${BaseButton} {
    min-width: 60px;
  }
`;

const DenseActions = styled(Actions)`
  margin-top: 6px;
`;

const Form = {
  Heading,
  Container,
  Label,
  GrayLabel,
  InfoText,
  Text,
  SpacedRow,
  Content,
  InputGroup,
  Actions,
  DenseActions,
};

export default Form;
