import { Auth } from "aws-amplify";
import validator from "validator";
import { US_STATES, US_STATES_MAP } from "./constants";

/* NOTE: In settings, a temporary button was placed to log Cognito user info*/
/* NOTE: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
   NOTE: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js = main implementation help
*/

/* HOWTO: use cognito token for authorization (not finalized/ tested)
 * 'Bearer ' might not work with lambda but should
  const response = await fetch(`${STRIPE_AWS_API}fetch_stripe_payment_methods`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    body: JSON.stringify({
      customer: testing_stripe_customer,
      testing: true,
    }),
  });
*/

/** You can either create your own account or use an existing one:
 * mrbraveknight@gmail.com
 * descartesBus1
 */
class CognitoClient {
  static UsernameExistsException = "UsernameExistsException";

  static UserNotConfirmedException = "UserNotConfirmedException";

  static isEmailValid(email) {
    return validator.isEmail(email);
  }

  static isPasswordValid(password) {
    return (
      validator.isStrongPassword(password, {
        minLength: 10,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      })
    );
  }

  static isNameValid(name) {
    return validator.isAlpha(name, "en-US", { ignore: " -,.'" });
  }

  static isPhoneValid(phone) {
    return validator.isMobilePhone(phone);
  }

  static normalizePhone(phone) {
    phone = phone.replaceAll(/[^0-9+]/g, "");
    if (phone.length >= 2 && phone.substring(0, 2) === "+1") {
      return phone;
    }
    return `+1${phone}`;
  }

  static isStateValid(state) {
    return US_STATES.includes(state);
  }

  static getStateCode(state) {
    return US_STATES_MAP[state];
  }

  static isZIPValid(zip) {
    return validator.isPostalCode(zip, "US");
  }

  static _toOpenIDAddress({ streetAddress, city, state, zip }) {
    const address = {
      street_address: streetAddress,
      locality: city,
      region: state,
      postal_code: zip,
      country: "US",
    };
    return JSON.stringify(address);
  }

  static strFromOpenIdDict(address) {
    return `${address.street_address}, ${address.locality}, ${address.region} ${address.postal_code}`;
  }

  static async signUp({
    email,
    password,
    firstName,
    lastName,
    phone,
    streetAddress,
    city,
    state,
    zip,
  }) {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          phone_number: phone,
          address: CognitoClient._toOpenIDAddress({
            streetAddress,
            city,
            state,
            zip,
          }),
        },
      });
    } catch (error) {
      error.message = `Couldn't create account:\n${error.message}`;
      throw error;
    }
  }

  static async confirmEmail({ email, code }) {
    try {
      await Auth.confirmSignUp(email, code);
    } catch (error) {
      throw new Error(`Couldn't confirm email:\n${error.message}`);
    }
  }

  static async resendConfirmationCode({ email }) {
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      throw new Error(`Couldn't resend confirmation:\n${error.message}`);
    }
  }

  static async signIn({ email, password }) {
    try {
      await Auth.signIn({ username: email, password });
      return true;
    } catch (error) {
      // https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#sign-in-with-custom-auth-challenges
      // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
      error.message = `Couldn't sign in:\n${error.message}`;
      throw error;
    }
  }

  static async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new Error(`Couldn't sign out:\n${error.message}`);
    }
  }

  static async sendPasswordResetEmail({ email }) {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      throw new Error(`Couldn't send password reset email:\n${error.message}`);
    }
  }

  static async submitPasswordReset({ email, code, newPassword }) {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
    } catch (error) {
      throw new Error(`Couldn't reset password:\n${error.message}`);
    }
  }

  static async changePassword({ oldPassword, newPassword }) {
    try {
      const user = await CognitoClient.getCognitoUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      throw new Error(`Couldn't change password:\n${error.message}`);
    }
  }

  static async getCognitoUser({ bypassCache = false } = {}) {
    try {
      return await Auth.currentAuthenticatedUser({ bypassCache });
    } catch (error) {
      throw new Error(`Couldn't get Cognito user:\n${error.message}`);
    }
  }

  static async isSignedIn() {
    try {
      if (await CognitoClient.getCognitoUser()) {
        return true;
      }
    } catch {}
    return false;
  }

  static async getJWTToken() {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch {}
    return null;
  }

  static async getCognitoAttributes(bypassCache = false) {
    return (await CognitoClient.getCognitoUser(bypassCache)).attributes;
  }

  static async _updateCognitoUserAttributes(newAttributes) {
    try {
      const user = await CognitoClient.getCognitoUser();
      await Auth.updateUserAttributes(user, newAttributes);
    } catch (error) {
      throw new Error(`Couldn't update Cognito attributes:\n${error.message}`);
    }
  }

  static async processNewAttributes({
    email,
    firstName,
    lastName,
    phone,
    streetAddress,
    city,
    state,
    zip,
  }) {
    try {
      const user = await CognitoClient.getCognitoUser();
      let updated = "";
      let newAttributes = {};
      if (email && email !== user.attributes.email) {
        newAttributes.email = email;
        updated += ", email";
      }
      if (firstName && firstName !== user.attributes.given_name) {
        newAttributes.given_name = firstName;
        updated += ", first name";
      }
      if (lastName && lastName !== user.attributes.family_name) {
        newAttributes.family_name = lastName;
        updated += ", last name";
      }
      if (phone && phone !== user.attributes.phone_number) {
        newAttributes.phone_number = phone;
        updated += ", phone number";
      }
      if (streetAddress || city || zip) {
        const { street_address, locality, region, postal_code } = JSON.parse(
          user.attributes.address
        );
        if (!streetAddress) {
          streetAddress = street_address;
        } else if (streetAddress !== street_address) {
          console.log(street_address, streetAddress);
          updated += ", street address";
        }
        if (!city) {
          city = locality;
        } else if (city !== locality) {
          updated += ", city";
        }
        if (!state) {
          state = region;
        } else if (state !== region) {
          updated += ", state";
        }
        if (!zip) {
          zip = postal_code;
        } else if (zip !== postal_code) {
          updated += ", zip";
        }
        const newAddress = this._toOpenIDAddress({
          streetAddress,
          city,
          state,
          zip,
        });
        if (newAddress !== user.attributes.address) {
          newAttributes.address = newAddress;
        }
      }
      console.log(newAttributes);
      if (newAttributes === {}) {
        throw new Error("No change in account info!");
      }
      await Auth.updateUserAttributes(user, newAttributes);
      return updated.slice(2);
    } catch (error) {
      error.message = `Couldn't update Cognito attributes:\n${error.message}`;
      throw error;
    }
  }

  static async updateName(newFirstName, newLastName) {
    try {
      await CognitoClient._updateCognitoUserAttributes({
        given_name: newFirstName,
        family_name: newLastName,
      });
    } catch (error) {
      throw new Error(`Couldn't update name:\n${error.message}`);
    }
  }

  static async updateEmail(newEmail) {
    try {
      await CognitoClient._updateCognitoUserAttributes({ email: newEmail });
    } catch (error) {
      throw new Error(`Couldn't update email:\n${error.message}`);
    }
  }

  static async updatePhone(newPhone) {
    try {
      await CognitoClient._updateCognitoUserAttributes({
        phone_number: newPhone,
      });
    } catch (error) {
      throw new Error(`Couldn't update phone:\n${error.message}`);
    }
  }

  static async updateAddress(streetAddress, city, state, zip) {
    try {
      await CognitoClient._updateCognitoUserAttributes({
        address: CognitoClient._toOpenIDAddress({
          streetAddress,
          city,
          state,
          zip,
        }),
      });
    } catch (error) {
      throw new Error(`Couldn't update address:\n${error.message}`);
    }
  }
}
export default CognitoClient;
