import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { Colors, Typography } from "./Theme";

const fadeIn = keyframes` 
  from { opacity: 0;}
  to { opacity: 1;}
`;

const Container = styled.div`
  position: relative;
`;

const Bubble = styled.div`
  ${Typography.regular}
  position: absolute;
  margin: auto;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-left: 0 auto;
  padding: 6px 10px;
  background: ${Colors.green};
  border-radius: 20px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.12);
  height: fit-content;
  width: fit-content;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Tooltip = ({ content, children }) => {
  const [show, setShow] = React.useState(false);
  return (
    <Container
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && <Bubble>{content}</Bubble>}
    </Container>
  );
};

export default Tooltip;
