import React from "react";
import Table, { TableCellLink } from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import { formatDollars, formatQuantity } from "utils/helpers";
import Button from "components/atoms/Button";
import { DataContext } from "providers/DataProvider";
import styled from "styled-components";
import Modal from "components/molecules/Modal";
import { Colors } from "components/atoms/Theme";
import Space from "components/atoms/Space";
import { cancelBasket } from "services";
import { ToastContext } from "providers/ToastProvider";

const CancelButton = styled(TableCellLink).attrs({ as: "div" })``;

const UpcomingOrders = ({ navigate, location: { state } }) => {
  const {
    refetchVendor,
    refetchPendingOrders,
    allPendingOrders,
    unitTags,
    vendor,
  } = React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);
  const [basketToCancel, setBasketToCancel] = React.useState();

  const columns = React.useMemo(
    () => [
      {
        Header: "Order #",
        accessor: "OrderNumber",
        columnWidth: "5%",
        Cell: ({ value }) => (value ? value.toString().slice(-4) : ""),
      },
      {
        Header: "Pickup time",
        accessor: "PickupStart",
        columnWidth: "18%",
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              })
            : "",
      },
      {
        Header: "Customer",
        accessor: "CustomerName",
        columnWidth: "16%",
      },
      {
        Header: "Product",
        accessor: "ProductName",
        columnWidth: "35%",
      },
      {
        Header: "Market",
        accessor: "MarketName",
        columnWidth: "35%",
      },
      {
        Header: "Quantity",
        accessor: "Quantity",
        columnWidth: "10%",
        Cell: ({ value, row }) =>
          formatQuantity(
            value,
            row.original.ProductID,
            unitTags,
            vendor.ProductArray
          ),
      },
      {
        Header: "Reward discount",
        accessor: "Discount",
        columnWidth: "12%",
        Cell: ({ value }) => (value ? formatDollars(value / 100) : "n/a"),
      },
      {
        Header: "Subtotal",
        accessor: "Cost",
        columnWidth: "13%",
        Cell: ({ value }) => formatDollars(value / 100),
      },
      {
        Header: "Status",
        accessor: "Status",
        columnWidth: "10%",
      },
      {
        Header: "Type",
        accessor: "IsDelivery",
        Cell: ({ value }) => (value ? "Delivery" : "Pickup"),
        columnWidth: "10%",
      },
      {
        Header: "",
        accessor: "BasketID",
        columnWidth: "20%",
        Cell: ({ value }) => (
          <CancelButton onClick={() => setBasketToCancel(value)}>
            Cancel basket
          </CancelButton>
        ),
      },
    ],
    [unitTags, vendor.ProductArray]
  );

  const handleCancel = () => {
    cancelBasket(basketToCancel).then((response) => {
      if (response.ok) {
        refetchPendingOrders();
        refetchVendor();
        showToast({
          message: "Basket cancelled.",
          type: "success",
        });
      } else {
        showToast({
          message: `Something went wrong cancelling the basket: ${response.body.message}`,
          type: "error",
        });
      }
    });
    setBasketToCancel();
  };

  const initialFilter = React.useMemo(
    () => (state ? state.filter : ""),
    [state]
  );

  React.useEffect(() => {
    refetchPendingOrders();
  }, [refetchPendingOrders]);

  return (
    <PageContainer heading="Upcoming orders">
      <Table
        columns={columns}
        data={allPendingOrders}
        loading={!allPendingOrders}
        initialGlobalFilter={initialFilter}
      >
        <Button onClick={() => navigate("all")}>see all orders</Button>
      </Table>
      <Modal
        title="Cancel basket?"
        visible={!!basketToCancel}
        setVisible={() => setBasketToCancel()}
        buttons={[
          {
            children: "no, don't cancel",
            color: Colors.gray,
            onClick: () => setBasketToCancel(),
          },
          {
            children: "yes, i'm sure",
            onClick: handleCancel,
          },
        ]}
      >
        Are you sure you want to cancel all orders in this customer's basket?
        The transaction will be canceled and the customer will be notified. This
        action cannot be undone!
        <Space height={10} />
        <div>These orders will be cancelled:</div>
        <ul>
          {basketToCancel &&
            allPendingOrders
              .filter((order) => order.BasketID === basketToCancel)
              .map((order) => (
                <li key={order.OrderID}>
                  <strong>{order.ProductName}</strong> (
                  {formatQuantity(
                    order.Quantity,
                    order.ProductID,
                    unitTags,
                    vendor.ProductArray
                  )}
                  )
                </li>
              ))}
        </ul>
      </Modal>
    </PageContainer>
  );
};

export default UpcomingOrders;
