import React from "react";
import withControl from "utils/withControl";
import styled from "styled-components";
import Button from "../atoms/Button";
import Row from "../atoms/Row";
import { Colors } from "../atoms/Theme";

const TagsContainer = styled(Row)`
  flex-wrap: wrap;
`;

const TagContainer = styled(Button)`
  margin-right: 15px;
  margin-bottom: 15px;
`;

const Tag = (props) => {
  return (
    <TagContainer color={props.selected ? Colors.red : Colors.gray} {...props}>
      {props.label}
    </TagContainer>
  );
};

const Tags = ({ value, tags, onChange }) => {
  const selectedTags = React.useMemo(
    () =>
      value
        .map((id) => tags.find((tag) => tag.TagID === id))
        .sort((a, b) => (a.TagText > b.TagText ? 1 : -1)),
    [value, tags]
  );
  const unselectedTags = React.useMemo(
    () =>
      tags
        .filter((tag) => !value.includes(tag.TagID))
        .sort((a, b) => (a.TagText > b.TagText ? 1 : -1)),
    [value, tags]
  );

  const selectTag = (tagId) => onChange((value) => [...value, tagId]);

  const deselectTag = (tagId) =>
    onChange((value) => value.filter((currTagId) => currTagId !== tagId));

  return (
    <TagsContainer>
      {selectedTags.map((tag) => (
        <Tag
          key={tag.TagID}
          label={tag.TagText}
          onClick={() => deselectTag(tag.TagID)}
          selected
        />
      ))}
      {unselectedTags.map((tag) => (
        <Tag
          key={tag.TagID}
          label={tag.TagText}
          onClick={() => selectTag(tag.TagID)}
        />
      ))}
    </TagsContainer>
  );
};

export const ControlledTags = withControl(Tags);

export default Tags;
