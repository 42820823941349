import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Button from "../components/atoms/Button";
import { DataContext } from "providers/DataProvider";
import Tooltip from "components/atoms/Tooltip";

const ManageMarkets = ({ navigate }) => {
  const { vendor, allPendingOrders } = React.useContext(DataContext);

  const data = React.useMemo(
    () =>
      vendor.MarketArray.map(
        ({ MarketID, Name, Location, NextOccurrences, StartTime, EndTime }) => {
          const products = vendor.ProductArray.filter((product) =>
            product.MarketArray.includes(MarketID)
          );
          const startTimeDate = new Date(
            `${NextOccurrences[0].replace(/-/g, "/")} ${StartTime}`
          );
          const dayOfWeek = startTimeDate.toLocaleDateString("en-US", {
            weekday: "short",
          });
          const startTimeString = startTimeDate.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          });
          const endTimeDate = new Date(
            `${NextOccurrences[0].replace(/-/g, "/")} ${EndTime}`
          );
          const endTimeString = endTimeDate.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
          });
          return {
            Name,
            Location,
            times: `${dayOfWeek} ${startTimeString} - ${endTimeString}`,
            products: products.length,
            orders: allPendingOrders.filter(
              (order) => order.MarketID === MarketID
            ).length,
            onClick: () => navigate(`edit/${MarketID}`),
            viewOrdersLink: { to: "/orders", state: { filter: Name } },
          };
        }
      ),
    [vendor.ProductArray, vendor.MarketArray, navigate, allPendingOrders]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Market name",
        accessor: "Name",
        columnWidth: "40%",
      },
      {
        Header: "Location",
        accessor: "Location",
        columnWidth: "25%",
      },
      {
        Header: "Time",
        accessor: "times",
        columnWidth: "25%",
      },
      {
        Header: "Products",
        accessor: "products",
        columnWidth: "10%",
      },
      {
        Header: "Pending orders",
        accessor: "orders",
        columnWidth: "10%",
      },
      {
        Header: "",
        accessor: "viewOrdersLink",
        isLink: true,
        linkText: "View orders",
        columnWidth: "18%",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <PageContainer heading={"Manage markets"}>
      <Table
        columns={columns}
        data={data}
        helpText="Click on a row to manage your products for that market."
        loading={true}
      >
        <Tooltip content="coming soon">
          <Button onClick={() => navigate("join")} disabled>
            join a market
          </Button>
        </Tooltip>
      </Table>
    </PageContainer>
  );
};

export default ManageMarkets;
