import React from "react";
import styled from "styled-components";
import Row from "./Row";
import { Colors } from "./Theme";
import { FaCheck } from "react-icons/fa";
import withControl from "utils/withControl";

const CheckboxContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? Colors.red : "#ffffff")};
  border: 2px solid ${(props) => (props.checked ? Colors.red : "#c4c4c4")};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  &:active {
    background: ${Colors.darkRed};
    border-color: ${Colors.darkRed};
  }
`;

/*
 * Props:
 *      - value [boolean] - if checkbox is selected
 *      - onChange [function] - function to run on value change
 */

const Checkbox = ({ value, onChange }) => {
  return (
    <CheckboxContainer checked={value} onClick={() => onChange(!value)}>
      <FaCheck color="white" />
    </CheckboxContainer>
  );
};

export const ControlledCheckbox = withControl(Checkbox);

export default Checkbox;
