import LoadingIndicator from "components/atoms/LoadingIndicator";
import { Colors, Typography } from "components/atoms/Theme";
import React from "react";
import { getAllTags, getItems, getOrders, getSeller } from "services";
import styled from "styled-components";
import { AuthContext } from "./AuthProvider";

const LoadingContainer = styled.div`
  ${Typography.heading};
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: ${Colors.green};
`;

export const DataContext = React.createContext({});

export const DataProvider = ({ children }) => {
  const { signedIn } = React.useContext(AuthContext);
  const [seller, setSeller] = React.useState({});
  const [vendor, setVendor] = React.useState({});
  const [vendorTags, setVendorTags] = React.useState([]);
  const [categoryTags, setCategoryTags] = React.useState([]);
  const [unitTags, setUnitTags] = React.useState([]);
  const [productTags, setProductTags] = React.useState([]);
  const [productGroupTags, setProductGroupTags] = React.useState([]);
  const [allPendingOrders, setAllPendingOrders] = React.useState([]);

  const fetchVendor = React.useCallback(async () => {
    await getSeller().then(({ body: { vendor, seller } }) => {
      setSeller(seller);
      // Resolve all object IDs
      const objectIds = [
        ...vendor.ProductArray,
        ...vendor.MarketArray,
        ...vendor.CouponArray,
      ];
      getItems(objectIds).then(({ body: { results } }) => {
        vendor.ProductArray = vendor.ProductArray.map((id) => results[id]);
        vendor.MarketArray = vendor.MarketArray.map((id) => results[id]);
        vendor.CouponArray = vendor.CouponArray.map((id) => {
          // Resolve coupons (for Sidebar)
          const coupon = results[id];
          if (coupon.ProductID) {
            const foundProduct = vendor.ProductArray.find(
              (product) => product.ProductID === coupon.ProductID
            );
            if (!foundProduct) {
              coupon.product = { Name: "(deleted product)" };
            } else {
              coupon.product = foundProduct;
            }
          }
          if (coupon.CategoryID) {
            coupon.category = categoryTags.find(
              (tag) => tag.TagID === coupon.CategoryID
            );
          }
          return coupon;
        });
        setVendor(vendor);
      });
    });
  }, [categoryTags]);

  const fetchPendingOrders = React.useCallback(
    () =>
      getOrders("PEND").then((data) => setAllPendingOrders(data.body.orders)),
    []
  );

  React.useEffect(() => {
    // Fetch all Tags
    getAllTags().then(({ body: { tags } }) => {
      setVendorTags(tags.filter((tag) => tag.TagID.startsWith("GV")));
      setCategoryTags(tags.filter((tag) => tag.TagID.startsWith("GC")));
      setUnitTags(tags.filter((tag) => tag.TagID.startsWith("GU")));
      setProductTags(tags.filter((tag) => tag.TagID.startsWith("GP")));
      setProductGroupTags(
        tags
          .filter((tag) => tag.TagID.startsWith("GG"))
          .map((group) => {
            // resolve category tags in group
            group.CategoryArray = group.CategoryArray.map((categoryId) =>
              tags.find((tag) => tag.TagID === categoryId)
            );
            return group;
          })
      );
    });
  }, []);

  React.useEffect(() => {
    if (categoryTags.length > 0 && signedIn) {
      fetchVendor();
      fetchPendingOrders();
    }
  }, [categoryTags, fetchVendor, signedIn, fetchPendingOrders]);

  const dataContext = React.useMemo(
    () => ({
      seller,
      vendor,
      vendorTags,
      unitTags,
      productTags,
      categoryTags,
      productGroupTags,
      allPendingOrders,
      refetchVendor: fetchVendor,
      refetchPendingOrders: fetchPendingOrders,
    }),
    [
      seller,
      vendor,
      vendorTags,
      unitTags,
      productTags,
      categoryTags,
      productGroupTags,
      allPendingOrders,
      fetchVendor,
      fetchPendingOrders,
    ]
  );

  // Signed in but data is still loading state
  if (signedIn && Object.keys(vendor).length === 0)
    return (
      <LoadingContainer>
        <LoadingIndicator />
        loading...
      </LoadingContainer>
    );

  return (
    <DataContext.Provider value={dataContext}>{children}</DataContext.Provider>
  );
};
