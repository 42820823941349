import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
} from "react-table";
import {
  FaAngleLeft,
  FaAngleRight,
  FaChevronDown,
  FaChevronUp,
  FaSearch,
} from "react-icons/fa";
import styled, { css } from "styled-components";
import Row from "./Row";
import Input from "./Input";
import { Colors, device, Typography } from "./Theme";
import IconButton from "./IconButton";
import Select from "./Select";
import Space from "./Space";
import { Link } from "@reach/router";
import { BaseButton } from "./Button";
import useResponsive from "hooks/useResponsive";

const Container = styled.div`
  width: ${(props) => props.width || "100%"};
  max-width: 1200px;
  height: fit-content;
  overflow: visible;
  @media ${device.mobile} and (orientation: portrait) {
    overflow-x: scroll;
    padding: 20px;
    padding-left: 0;
  }
  @media ${device.desktop} and (orientation: landscape) {
    overflow: visible;
  }
`;

const TableContainer = styled.div`
  background: #ffffff;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  padding: 18px;
  @media ${device.mobile} and (orientation: portrait) {
    width: max-content;
  }
  @media ${device.desktop} and (orientation: landscape) {
    width: unset;
  }
`;

const TableHat = styled(Row)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  ${BaseButton} {
    ${Typography.regular}
  }
`;

const TableHead = styled.div`
  margin-bottom: 6px;
  padding: 8px 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
`;

const TableHeaderGroup = styled(Row)`
  flex-wrap: nowrap !important;
`;

const TableHeader = styled(Row)`
  ${Typography.small}
  font-weight: 500;
  color: black;
  width: ${(props) =>
    props.columnWidth ? props.columnWidth : `${100 / props.numColumns}%`};
  margin: 0 5px;
  -webkit-user-select: none; /* Safari */
  user-select: none;
  align-items: center;
`;

const DownCaret = styled(FaChevronDown)`
  color: #949494;
  margin-left: 4px;
`;

const UpCaret = styled(DownCaret).attrs({
  as: FaChevronUp,
})``;

const TableBody = styled.div``;

const TableRow = styled(Row)`
  flex-wrap: nowrap !important;
  background: white;
  padding: 10px 5px;
  align-items: center;
  position: relative;
  justify-content: ${(props) => (props.info ? "center" : "flex-start")};
  &:hover {
    background: ${Colors.hoveredGray};
  }
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:active {
        background: ${Colors.clickedGray};
      }
    `}
  /* bottom border */
  &:after {
    content: "";
    background: ${Colors.gray};
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 1px;
    width: calc(100% - 20px);
  }
`;

const TableCell = styled.div`
  ${Typography.regular}
  font-weight: 400;
  line-height: 28px;
  width: ${(props) =>
    props.columnWidth ? props.columnWidth : `${100 / props.numColumns}%`};
  color: ${(props) => (props.color ? props.color : "black")};
  margin: 0 5px;
  text-overflow: ellipsis;
  text-align: ${(props) => (props.isLink ? "right" : "left")};
  overflow: ${(props) => (props.isLink ? "visible" : "hidden")};
  word-wrap: break-word;
  white-space: nowrap;
`;

export const TableCellLink = styled(Link)`
  ${Typography.regular}
  font-weight: 500;
  text-decoration-line: underline;
  color: ${Colors.red};
  cursor: pointer;
  &:hover,
  &:active {
    color: #cf4444;
  }
`;

const HelpText = styled.div`
  ${Typography.small}
  font-weight: 400;
  color: #a3a3a3;
  margin: 10px;
  flex: 6;
`;

const CounterText = styled(HelpText)`
  flex: 1;
  text-align: right;
`;

// Search Bar

const SearchContainer = styled.div`
  position: relative;
  width: 45%;
`;

const SearchIcon = styled(FaSearch)`
  color: ${Colors.gray};
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 18px;
`;

const SearchBar = ({ value, onChange }) => {
  const [searchQuery, setSearchQuery] = React.useState(value);

  return (
    <SearchContainer>
      <Input
        placeholder="search..."
        value={searchQuery}
        onChange={(value) => {
          setSearchQuery(value);
          onChange(value);
        }}
      />
      <SearchIcon />
    </SearchContainer>
  );
};

const ChildrenContainer = styled(Row)`
  flex: 1;
  justify-content: flex-end;
`;

// Pagination

const PaginationContainer = styled(Row)`
  margin-left: 40px;
  align-items: center;
`;

const getHeaderProps = ({ columnWidth }) => {
  return { columnWidth };
};

const getRowProps = ({ original }) => {
  const { onClick } = original;
  return { onClick };
};

const getCellProps = ({ column }) => {
  const { columnWidth, cellColor, isLink } = column;
  return { columnWidth, color: cellColor, isLink };
};

/*
 * Props:
 *      - columns [Object[]]
 *           - columns definitions (must be memoized!)
 *           - refer to https://react-table.tanstack.com/docs/api/useTable#column-options for column properties
 *           - these additional properties may be included in the column objects:
 *              - columnWidth? [string] - percentage width for column. if not set, it will try to split up the columns evenly
 *              - cellColor? [string] - text color for all cells in the column
 *              - disableSortBy? [boolean] - disables sorting for column. default: false
 *              - isLink? [boolean] - true if a link should be rendered (the value of accessor is treated as the link)
 *              - linkText? [string] - default link text to show on the link (required if isLink === true).
 *      - data [Object[]]
 *           - rows of objects representing data to show (must be memoized!)
 *           - each object should have keys corresponding to the 'accessors' defined in the 'columns' prop
 *           - these additional properties may be included in the data objects:
 *              - onClick? [function] - runs when user clicks on a row
 *              - [column accessor]Text? [string] - custom link text for this row if isLink === true for the column
 *                    - if not provided, the link text will be the default linkText specified by the column
 *                    - ie. if column accessor is 'viewOrdersLink', then use 'viewOrdersLinkText' to set link text for this row
 *      - searchable? [boolean] - allow global searching for entire table. default: true
 *      - maxRowsPerPage? [number] - max rows to show per page. set this to enable pagination. default: 100 (so basically no pagination)
 *      - helpText? [string] - help text to show under table
 *      - loading? [boolean] - show loading state if data is empty. default: false
 *      - children? [ReactElement] - content to show to the right of the search bar if desired
 *      - width? [string] - width of table (ie. '100%' or '500px')
 *      - initialSort? [object[]] - initial sort state if desired (https://react-table.tanstack.com/docs/api/useSortBy#table-options)
 *      - initialGlobalFilter? [string] - initial search query for the table if desired
 */

const Table = ({
  columns,
  data,
  maxRowsPerPage = 100,
  searchable = true,
  helpText,
  loading,
  children,
  width,
  initialSort,
  initialGlobalFilter,
  ...otherProps
}) => {
  const { isMobile } = useResponsive();
  const defaultSortState = React.useMemo(
    () => [{ id: columns[0].accessor }],
    [columns]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    rows,
    preGlobalFilteredRows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: maxRowsPerPage,
        sortBy: initialSort || defaultSortState,
        globalFilter: initialGlobalFilter,
      },
      disableSortRemove: true,
      ...otherProps,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const onGlobalFilterChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Container width={width}>
      <TableContainer {...getTableProps()}>
        {(searchable || children || pageCount > 1) && (
          <TableHat>
            {searchable && (
              <SearchBar
                value={state.globalFilter}
                onChange={onGlobalFilterChange}
              />
            )}
            <ChildrenContainer>{children}</ChildrenContainer>
            {pageCount > 1 && (
              <PaginationContainer>
                <IconButton
                  icon={FaAngleLeft}
                  color={Colors.green}
                  size={24}
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                />
                <Space width={10} />
                <Select
                  options={[...new Array(pageCount).keys()].map((index) => ({
                    label: index + 1,
                    value: index + 1,
                  }))}
                  value={pageIndex + 1}
                  onChange={(page) => gotoPage(page - 1)}
                  showIcon={false}
                />
                <Space width={10} />
                <IconButton
                  icon={FaAngleRight}
                  color={Colors.green}
                  size={24}
                  onClick={nextPage}
                  disabled={!canNextPage}
                />
              </PaginationContainer>
            )}
          </TableHat>
        )}
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableHeaderGroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader
                  {...column.getHeaderProps([
                    getHeaderProps(column),
                    column.getSortByToggleProps(),
                  ])}
                  numColumns={headerGroup.headers.length}
                >
                  {column.render("Header")}
                  {column.isSorted &&
                    (column.isSortedDesc ? <DownCaret /> : <UpCaret />)}
                </TableHeader>
              ))}
            </TableHeaderGroup>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps(getCellProps(cell))}
                      numColumns={columns.length}
                    >
                      {cell.column.isLink ? (
                        <TableCellLink
                          to={cell.value.to}
                          state={cell.value.state}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {cell.row.original[`${cell.column.id}Text`] ||
                            cell.column.linkText}
                        </TableCellLink>
                      ) : (
                        cell.render("Cell")
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {/* no search results / no data state */}
          {!loading &&
            ((preGlobalFilteredRows.length > 0 && rows.length === 0) ||
              data.length === 0) && (
              <TableRow info>
                <TableCell color={Colors.gray}>Nothing here</TableCell>
              </TableRow>
            )}
          {/* loading state */}
          {loading && data.length === 0 && (
            <TableRow info>
              <TableCell color={Colors.gray}>Loading...</TableCell>
            </TableRow>
          )}
        </TableBody>
        <Row align="flex-start" justify="space-between">
          <HelpText>{helpText}</HelpText>
          <CounterText>
            {pageCount === 1 ? 1 : pageIndex * pageSize + 1} to{" "}
            {pageCount === 1
              ? pageCount === 1
                ? rows.length
                : pageSize
              : Math.min(rows.length, pageIndex * pageSize + pageSize)}{" "}
            of {rows.length}
          </CounterText>
        </Row>
        {isMobile && (
          <HelpText>TIP: Rotate your device into landscape mode</HelpText>
        )}
      </TableContainer>
    </Container>
  );
};

export default Table;
