/**
 * api.js
 *
 * Author: William Wu
 *
 * © 2021 William Wu. All rights reserved.
 */

import { getData, sendData, uploadImages } from "./data";

export const getItems = async (ids) => {
  const path = `/UnifiedRetrieval?objectID=${ids.filter(Boolean).join(",")}`;
  const response = await getData(path);
  if (response.ok) {
    let results = response.body.results;
    for (const id in results) {
      if (typeof results[id] !== "object") {
        console.error(`Error retrieving ID ${id}: ${results[id]}`);
        delete results[id];
      }
    }
  }
  return response;
};

export const getSeller = async () => {
  return await getData("/sellers");
};

export const getAllTags = async () => {
  return await getData("/tags");
};

export const getOrders = async (status) => {
  let path = "/orders";
  if (status) path += `?status=${status}`;
  return await getData(path);
};

export const updateVendor = async (vendor) => {
  vendor.ProductArray = toProductIDArray(vendor.ProductArray);
  vendor.MarketArray = toMarketIDArray(vendor.MarketArray);
  vendor.CouponArray = toCouponIDArray(vendor.CouponArray);
  const body = JSON.stringify({ vendor });
  return await sendData("/vendors", "PUT", body);
};

export const createProduct = async (product) => {
  const body = JSON.stringify({ product });
  return await sendData("/products", "POST", body);
};

export const updateProduct = async (product) => {
  const body = JSON.stringify({ product });
  return await sendData("/products", "PUT", body);
};

export const deleteProduct = async (productID) => {
  const body = JSON.stringify({ ProductID: productID });
  return await sendData("/products", "DELETE", body);
};

export const createCoupon = async (coupon) => {
  const body = JSON.stringify({ coupon });
  return await sendData("/coupons", "POST", body);
};

export const updateCoupon = async (coupon) => {
  const body = JSON.stringify({ coupon });
  return await sendData("/coupons", "PUT", body);
};

export const deleteCoupon = async (couponID) => {
  const body = JSON.stringify({ CouponID: couponID });
  return await sendData("/coupons", "DELETE", body);
};

export const cancelPendingBasket = async (basketID) => {
  const body = JSON.stringify({ BasketID: basketID });
  return await sendData("/orders", "DELETE", body);
};

// Images
export const uploadVendorImages = (images) => {
  return uploadImages("/vendors/images", images);
};

export const deleteVendorImages = (images) => {
  if (images.length === 0) return Promise.resolve();
  const body = JSON.stringify({ images });
  return sendData("/vendors/images", "DELETE", body);
};

export const uploadProductImages = (productId, images) => {
  return uploadImages("/products/images", images, { ProductID: productId });
};

export const deleteProductImages = (images) => {
  if (images.length === 0) return Promise.resolve();
  const body = JSON.stringify({ images });
  return sendData("/products/images", "DELETE", body);
};

// Stripe
export const getStripeCreateLink = () => {
  return getData(`/account_link?redirect=${window.location.origin}`);
};

export const stripeAccountVerify = () => {
  return sendData("/verify_account_link", "PUT");
};

// Orders

export const completeOrder = async (basketId) => {
  const body = JSON.stringify({ BasketID: basketId });
  return await sendData("/orders/complete", "POST", body);
};

export const cancelBasket = async (basketId) => {
  const body = JSON.stringify({ BasketID: basketId });
  return await sendData("/orders/seller", "DELETE", body);
};

// Onsite

export const rewardPoints = async (UserID, points) => {
  const body = JSON.stringify({ UserID, points });
  return await sendData("/onsite", "POST", body);
};

// Helpers

const toProductIDArray = (arr) => arr.map((obj) => obj.ProductID);
const toMarketIDArray = (arr) => arr.map((obj) => obj.MarketID);
const toCouponIDArray = (arr) => arr.map((obj) => obj.CouponID);
