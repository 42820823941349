import React from "react";
import styled from "styled-components";
import { Redirect, Router } from "@reach/router";
import Sidebar from "./components/molecules/Sidebar";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AccountUpdate from "./pages/AccountUpdate";
import ResetPassword from "./pages/ResetPassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import StripeSetup from "./pages/StripeSetup";
import ConfirmStripe from "./pages/ConfirmStripe";
import { AuthContext, AuthProvider } from "providers/AuthProvider";
import { DataContext, DataProvider } from "./providers/DataProvider";
import { ToastProvider } from "providers/ToastProvider";
import GeneralInfo from "./pages/GeneralInfo";
import ManageProducts from "./pages/ManageProducts";
import NewProduct from "./pages/NewProduct";
import ManageMarkets from "./pages/ManageMarkets";
import JoinMarket from "./pages/JoinMarket";
import EditMarket from "./pages/EditMarket";
import ManageRewards from "./pages/ManageRewards";
import NewReward from "./pages/NewReward";
import AllOrders from "./pages/AllOrders";
import Amplify from "aws-amplify";
import awsConfig from "./utils/aws-exports";
import UpcomingOrders from "./pages/UpcomingOrders";
import Pickup from "./pages/Pickup";
import Onsite from "./pages/Onsite";
import NotFound from "pages/NotFound";
Amplify.configure(awsConfig);

const AppContainer = styled.div`
  display: flex;
`;

// Routes for signed out vendors
const unprotectedRoutes = (
  <>
    <Redirect from="/" to="/signin" noThrow />
    <SignIn path="/signin" />
    <SignUp path="/signup" />
    <ResetPassword path="/reset-password" />
    <ConfirmEmail path="/confirm-email" />
  </>
);

// Routes for signed in vendors that have not setup their stripe account
const stripeSetupRoutes = (
  <>
    <Redirect from="/" to="/stripe-setup" noThrow />
    <StripeSetup path="/stripe-setup" />
    <ConfirmStripe path="/confirm-stripe" />
    <GeneralInfo path="/me" />
  </>
);

// Routes for signed in vendors that have not onboarded (updated their vendor listing)
const onboardingRoutes = (
  <>
    <Redirect from="/" to="/me" noThrow />
    <GeneralInfo path="/me" />
  </>
);

// Routes for signed in vendors
const protectedRoutes = (
  <>
    <Home path="/" />
    <GeneralInfo path="/me" />
    <AccountUpdate path="/account-update" />
    <ManageProducts path="/products" />
    <NewProduct path="/products/new" />
    <NewProduct path="/products/edit/:productId" editable />
    <ManageMarkets path="/markets" />
    <JoinMarket path="/markets/join" />
    <EditMarket path="/markets/edit/:marketId" />
    <ManageRewards path="/rewards" />
    <NewReward path="/rewards/new" />
    <NewReward path="/rewards/edit/:couponId" editable />
    <AllOrders path="/orders/all" />
    <UpcomingOrders path="/orders" />
    <Pickup path="/pickup" />
    <Pickup path="/pickup/:paramOrderNumber" />
    <Onsite path="/onsite" />
    <Onsite path="/onsite/:paramUserId" />
  </>
);

const AppRouter = () => {
  const { signedIn } = React.useContext(AuthContext);
  const { seller } = React.useContext(DataContext);
  console.log(
    "signed in?",
    signedIn,
    "onboarded?",
    seller.Onboarded,
    "stripe complete?",
    seller.StripeAccountCompleted
  );
  const currentRoutes = React.useMemo(() => {
    if (!signedIn) return unprotectedRoutes;
    if (!seller.StripeAccountCompleted) return stripeSetupRoutes;
    if (!seller.Onboarded) return onboardingRoutes;
    return protectedRoutes;
  }, [signedIn, seller.Onboarded, seller.StripeAccountCompleted]);

  return (
    <Router style={{ width: "100%" }}>
      {currentRoutes}
      <NotFound default />
    </Router>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <DataProvider>
        <AppContainer>
          <ToastProvider>
            <Sidebar />
            <AppRouter />
          </ToastProvider>
        </AppContainer>
      </DataProvider>
    </AuthProvider>
  );
};

export default App;
