/**
 * data.js
 *
 * Author: William Wu
 *
 * © 2021 William Wu. All rights reserved.
 */

import CognitoClient from "utils/CognitoClient";

const API_URL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8000";

const makeRequest = async (
  path,
  method = "GET",
  headers = {},
  body = undefined
) => {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  const options = {
    method,
    headers: {
      Authorization: await CognitoClient.getJWTToken(),
      ...headers,
    },
  };
  if (body) options.body = body;
  const response = await fetch(`${API_URL}${path}`, options);
  const responseBody = await response.json();
  return {
    status: response.status,
    ok: response.ok,
    body: responseBody,
  };
};

export const getData = async (path) => {
  return makeRequest(path);
};

export const sendData = async (path, method, body) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return makeRequest(path, method, headers, body);
};

const uploadFile = async ({ url, fields }, file) => {
  const formData = new FormData();
  fields["Content-Type"] = file.type;
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });
  if (response.status !== 204) throw new Error(await response.text());
};

const getPresignedUploadUrls = async (path, images, body = {}) => {
  const get_urls_body = body;
  if ("logo" in images) get_urls_body.logo = true;
  if ("cover" in images) get_urls_body.cover = true;
  if ("images" in images) get_urls_body.images = images.images.length;
  const response = await sendData(path, "POST", JSON.stringify(get_urls_body));
  return response.body.urls;
};

export const uploadImages = async (path, images, body = {}) => {
  const urls = await getPresignedUploadUrls(path, images, body);
  const uploads = [];
  Object.keys(images).forEach((key) => {
    switch (key) {
      case "logo":
        uploads.push(uploadFile(urls.logo, images.logo));
        break;
      case "cover":
        uploads.push(uploadFile(urls.cover, images.cover));
        break;
      case "images":
        images.images.forEach((image, index) =>
          uploads.push(uploadFile(urls.images[index], image))
        );
        break;
      default:
        throw new Error("Invalid key");
    }
  });
  return await Promise.all(uploads);
};
