import React from "react";
import styled from "styled-components";
import withControl from "utils/withControl";
import CameraIconButton from "./CameraIconButton";
import { DataContext } from "providers/DataProvider";
import Row from "components/atoms/Row";
import { Typography, Colors } from "components/atoms/Theme";
import { navigate } from "@reach/router";
import { ClickAwayListener } from "@material-ui/core";
import { AuthContext } from "providers/AuthProvider";
import { css } from "styled-components";

const EditButton = styled(CameraIconButton)`
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

const VendorLogoContainer = styled.div`
  width: ${(props) => (props.edit ? 160 : 67)}px;
  height: ${(props) => (props.edit ? 160 : 67)}px;
  border-radius: 50%;
  border: 1px solid #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${(props) =>
    !props.edit &&
    css`
      cursor: pointer;
    `}
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  right: 10px;
  width: 140px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 12px 12px;
  overflow-x: hidden;
  z-index: ${(props) => (props.open ? 2 : 0)};
`;

const Option = styled(Row)`
  ${Typography.regular}
  user-select: none;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background: ${Colors.green};
    color: white;
  }
`;

const VendorLogo = ({ edit, value, onChange }) => {
  const { vendor } = React.useContext(DataContext);
  const { signOut } = React.useContext(AuthContext);
  const [showMenu, setShowMenu] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState(vendor.LogoImage);
  const fileRef = React.createRef();

  React.useEffect(() => {
    if (!imageUrl) setImageUrl(value);
  }, [value, imageUrl]);

  React.useEffect(() => {
    setImageUrl(vendor.LogoImage);
  }, [vendor.LogoImage]);

  const onFileChange = async (event) => {
    const { target } = event;

    const item = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(target.files[0]);
      reader.onload = () => {
        resolve({ file: target.files[0], base64: reader.result }); //! MOCK photo uploading. TODO: replace with actual flow
      };
      reader.onerror = reject;
    });
    setImageUrl(item.base64);
    onChange(item.file);
    target.value = "";
  };

  return (
    <VendorLogoContainer
      edit={edit}
      src={imageUrl}
      onClick={() => setShowMenu(!edit)}
    >
      <input
        type="file"
        onChange={onFileChange}
        ref={fileRef}
        accept="image/*"
        style={{ display: "none" }}
      />
      {edit && <EditButton onClick={() => fileRef.current.click()} />}
      {!edit && showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <Menu>
            <Option onClick={() => navigate("/account-update")}>
              Account info
            </Option>
            <Option
              onClick={() => {
                signOut();
                navigate("/");
              }}
            >
              Sign out
            </Option>
          </Menu>
        </ClickAwayListener>
      )}
    </VendorLogoContainer>
  );
};

export default VendorLogo;

export const ControlledVendorLogo = withControl(VendorLogo);
