import logo from "assets/images/logo.svg";
import Row from "components/atoms/Row";
import Image from "components/atoms/Image";
import { Colors, Typography } from "components/atoms/Theme";
import styled from "styled-components";
import Column from "components/atoms/Column";

const Content = styled(Row)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Heading = styled.div`
  ${Typography.heading}
  color: ${Colors.green};
`;

const NotFound = () => {
  return (
    <Content>
      <Column align="flex-start">
        <Image src={logo} alt="HarvestHaul Logo" height={120} />
        <Heading>oops, there's nothing here :(</Heading>
      </Column>
    </Content>
  );
};

export default NotFound;
