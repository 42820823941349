import React from "react";

const useKeyPress = (targetKey, { onKeyPress, onKeyUp }) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  const onKeyDownHandler = React.useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
        if (onKeyPress) onKeyPress();
      }
    },
    [onKeyPress, targetKey]
  );

  const onKeyUpHandler = React.useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
        if (onKeyUp) onKeyUp();
      }
    },
    [onKeyUp, targetKey]
  );

  React.useEffect(() => {
    window.addEventListener("keydown", onKeyDownHandler);
    window.addEventListener("keyup", onKeyUpHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", onKeyDownHandler);
      window.removeEventListener("keyup", onKeyUpHandler);
    };
  }, [onKeyDownHandler, onKeyUpHandler]);
  return keyPressed;
};

export default useKeyPress;
