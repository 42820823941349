import styled from "styled-components";
import Topbar from "../molecules/Topbar";
import Column from "../atoms/Column";

const ContentContainer = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

const PageContainer = ({ children, heading, subheading, logo }) => {
  return (
    <Column style={{ flex: 1 }}>
      <Topbar
        heading={heading}
        subheading={subheading}
        logo={logo !== undefined ? logo : true}
      />
      <ContentContainer>{children}</ContentContainer>
    </Column>
  );
};

export default PageContainer;
