import React from "react";
import PageContainer from "../components/templates/PageContainer";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import { ControlledColorPicker } from "../components/molecules/ColorPicker";
import { ControlledInput } from "../components/atoms/Input";
import { ControlledVendorLogo } from "../components/molecules/VendorLogo";
import { Colors } from "../components/atoms/Theme";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { ControlledCoverPhoto } from "../components/molecules/CoverPhoto";
import { ControlledPhotos } from "../components/molecules/Photos";
import { useForm } from "react-hook-form";
import validator from "validator";
import { DataContext } from "../providers/DataProvider";
import { deleteVendorImages, updateVendor, uploadVendorImages } from "services";
import { ControlledTags } from "components/molecules/Tags";
import { ToastContext } from "providers/ToastProvider";
import styled from "styled-components";

const DenseActions = styled(Form.Actions)`
  margin-top: 6px;
`;

const GeneralInfo = ({ navigate }) => {
  const { seller, vendor, vendorTags, refetchVendor } =
    React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { TagArray: [] },
  });

  React.useEffect(() => {
    reset(vendor);
  }, [reset, vendor]);

  const onSubmit = ({
    Name,
    Tagline,
    Description,
    WebsiteURL,
    TagArray,
    Color,
    LogoImage,
    CoverImage,
    ImageArray,
  }) => {
    const updatedVendor = {
      ...vendor,
      Name,
      Tagline,
      Description,
      WebsiteURL,
      TagArray,
      Color,
    };
    // Upload/remove images
    const imagesToDelete = [];
    const imagesToUpload = {};
    if (typeof LogoImage !== "string") {
      if (vendor.LogoImage.length > 0) imagesToDelete.push(vendor.LogoImage);
      imagesToUpload.logo = LogoImage;
    }
    if (typeof CoverImage !== "string") {
      if (vendor.CoverImage.length > 0) imagesToDelete.push(vendor.CoverImage);
      imagesToUpload.cover = CoverImage;
    }
    // Calculate images to remove (for Photos field)
    imagesToDelete.push(
      ...vendor.ImageArray.filter((url) => !ImageArray.includes(url))
    );
    // Calculate new images to upload (for Photos field)
    const newVendorPhotos = ImageArray.filter((obj) => typeof obj !== "string");
    if (newVendorPhotos.length > 0) {
      imagesToUpload.images = newVendorPhotos;
    }
    deleteVendorImages(imagesToDelete)
      .then(() => {
        showToast({
          message: "Uploading images...",
          type: "success",
        });
        return uploadVendorImages(imagesToUpload);
      })
      .then(() => updateVendor(updatedVendor))
      .then(async (response) => {
        if (response.ok) {
          await refetchVendor();
          showToast({
            message: "Successfully updated!",
            type: "success",
          });
          if (!seller.StripeAccountCompleted) {
            navigate("/confirm-stripe");
          } else {
            navigate("/");
          }
        } else {
          throw new Error(response.body.message);
        }
      })
      .catch((e) => {
        console.error(e);
        showToast({
          message: `Something went wrong: ${e}`,
          type: "error",
        });
      });
  };

  const onError = (errors) => {
    console.log(errors)
    // showToast({
    //   message: `Validation failed: ${Object.keys(errors).join(", ")}`,
    //   type: "error",
    // });
  };

  return (
    <PageContainer heading="Edit profile">
      <BoxContainer width="1086px">
        <Form.Container>
          <ControlledCoverPhoto
            name="CoverImage"
            control={control}
            editable
            helpText="Upload your cover photo:"
          />
          <Form.Content>
            <Form.InputGroup>
              <Form.SpacedRow justify="flex-start">
                <Column flex={3} align="center" justify="center">
                  <Form.Label>Display Name</Form.Label>
                  <ControlledInput
                    name="Name"
                    control={control}
                    maxChars={100}
                    width="100%"
                    rules={{ required: true }}
                  />
                </Column>
                <Column flex={1} align="center">
                  <Form.Label>Logo</Form.Label>
                  <ControlledVendorLogo
                    name="LogoImage"
                    control={control}
                    edit
                  />
                </Column>
                <Column flex={1} align="center">
                  <Form.Label>Display Color</Form.Label>
                  <ControlledColorPicker name="Color" control={control} />
                </Column>
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Tagline</Form.Label>
              <ControlledInput
                name="Tagline"
                control={control}
                numLines={3}
                maxChars={200}
                helpText="Your optional tagline is displayed in bold above the rest of your description."
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Description</Form.Label>
              <ControlledInput
                name="Description"
                control={control}
                numLines={5}
                maxChars={2000}
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Website</Form.Label>
              <ControlledInput
                name="WebsiteURL"
                control={control}
                maxChars={100}
                helpText="(Optional) A link to your website that customers can visit"
                rules={{
                  required: false,
                  validate: (url) =>
                    url.length === 0 || validator.isURL(url, { require_protocol: true }),
                }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <ControlledPhotos
                control={control}
                name="ImageArray"
                defaultValue={[]}
                editable
              />
            </Form.InputGroup>
            <Form.Label>Tags</Form.Label>
            <ControlledTags
              name="TagArray"
              control={control}
              tags={vendorTags}
            />
            <Form.Actions gap={10}>
              {seller.Onboarded && (
                <Button color={Colors.gray} onClick={() => navigate("..")}>
                  cancel
                </Button>
              )}
              <Button onClick={handleSubmit(onSubmit, onError)}>save</Button>
            </Form.Actions>
            {seller.Onboarded && seller.StripeAccountCompleted && (
              <DenseActions>
                <Button onClick={() => navigate("/account-update")}>
                  go to account
                </Button>
              </DenseActions>
            )}
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default GeneralInfo;
