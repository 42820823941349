import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Button from "../components/atoms/Button";
import { DataContext } from "../providers/DataProvider";
import { formatProductPrice, resolveTagText } from "../utils/helpers";

const ManageProducts = ({ navigate }) => {
  const { vendor, unitTags, categoryTags, allPendingOrders } =
    React.useContext(DataContext);
  const data = React.useMemo(
    () =>
      vendor.ProductArray.map((product) => ({
        ...product,
        onClick: () => {
          navigate(`edit/${product.ProductID}`, {
            state: { product },
          });
        },
        PendingOrders: allPendingOrders.filter(
          (order) => order.ProductID === product.ProductID
        ).length,
        viewOrdersLink: { to: "/orders", state: { filter: product.Name } },
      })),
    [navigate, vendor.ProductArray, allPendingOrders]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Product name",
        accessor: "Name",
        columnWidth: "40%",
      },
      {
        Header: "Category",
        accessor: "CategoryID",
        Cell: ({ value }) => resolveTagText(categoryTags, value),
        columnWidth: "12%",
      },
      {
        Header: "Unit price",
        accessor: "PriceMap",
        Cell: ({
          row: {
            original: { PriceMap, PriceUnitID },
          },
        }) => formatProductPrice(PriceMap, unitTags, PriceUnitID),
        columnWidth: "18%",
      },
      {
        Header: "Reward points",
        accessor: "RewardPoints",
        columnWidth: "10%",
      },
      {
        Header: "Markets",
        accessor: "MarketArray",
        Cell: ({ value }) => value.length,
        columnWidth: "10%",
      },
      {
        Header: "Current rating",
        accessor: "NumReviews",
        Cell: ({ row }) => {
          const {
            original: { NumStars, NumReviews },
          } = row;
          return NumReviews > 0 ? (NumStars / NumReviews).toFixed(2) : "n/a";
        },
        columnWidth: "10%",
      },
      {
        Header: "Pending orders",
        accessor: "PendingOrders",
        columnWidth: "10%",
      },
      {
        Header: "",
        accessor: "viewOrdersLink",
        isLink: true,
        linkText: "View orders",
        columnWidth: "10%",
        disableSortBy: true,
      },
    ],
    [categoryTags, unitTags]
  );
  return (
    <PageContainer heading="Manage products">
      <Table
        columns={columns}
        data={data}
        helpText="Click on a row to edit that product's listing and information."
      >
        <Button to="new">new product</Button>
      </Table>
    </PageContainer>
  );
};

export default ManageProducts;
