import { Link as DefaultLink } from "@reach/router";
import styled from "styled-components";
import { Colors } from "./Theme";

const Link = styled(DefaultLink)`
  color: ${Colors.red};
  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
