import React from "react";
import styled from "styled-components";
import CameraIconButton from "./CameraIconButton";
import ProfileBg from "../../assets/images/my-profile-bg.svg";
import Column from "../atoms/Column";
import Row from "../atoms/Row";
import { Typography } from "../atoms/Theme";
import withControl from "utils/withControl";

const CoverPhotoContainer = styled(Column)`
  background-image: ${(props) => `url(${props.src || ProfileBg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 16px;
  height: 120px;
  margin-bottom: -30px;
`;

const EditActionContainer = styled(Row)`
  align-self: flex-end;
`;

const EditCoverButton = styled(CameraIconButton)``;

const HelpText = styled.span`
  ${Typography.small}
  color: black;
  margin-right: 14px;
  font-weight: 400;
`;

/*
 * Props:
 *      - value [string] - uri of image
 *      - onChange [function] - function to run on value change
 *      - editable [boolean] - if photo can be changed (upload button will show)
 *      - helpText [string] - text to show next to upload button if no photo
 */

const CoverPhoto = React.forwardRef(
  ({ value, onChange, editable, helpText }, ref) => {
    const [imageUrl, setImageUrl] = React.useState(value);
    const fileRef = React.createRef();

    React.useEffect(() => {
      if (!imageUrl) setImageUrl(value);
    }, [value, imageUrl]);

    const onFileChange = async (event) => {
      const { target } = event;

      const item = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
          resolve({ file: target.files[0], base64: reader.result }); //! MOCK photo uploading. TODO: replace with actual flow
        };
        reader.onerror = reject;
      });
      setImageUrl(item.base64);
      onChange(item.file);
      target.value = "";
    };

    return (
      <CoverPhotoContainer ref={ref} src={imageUrl}>
        <input
          type="file"
          onChange={onFileChange}
          ref={fileRef}
          accept="image/*"
          style={{ display: "none" }}
        />
        {editable && (
          <EditActionContainer align="center">
            {!value && <HelpText>{helpText}</HelpText>}
            <EditCoverButton onClick={() => fileRef.current.click()} />
          </EditActionContainer>
        )}
      </CoverPhotoContainer>
    );
  }
);

export const ControlledCoverPhoto = withControl(CoverPhoto);

export default CoverPhoto;
