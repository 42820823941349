export const formatDollars = (price) => {
  if (isNaN(price)) return "";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(price);
};

export const resolveTagText = (tagArray, tagId) => {
  const foundTag = tagArray.find((tag) => tag.TagID === tagId);
  if (foundTag) return foundTag.TagText;
  return "";
};

export const formatProductPrice = (priceMap, unitTags, priceUnitId) => {
  const prices = Object.values(priceMap);
  prices.sort((a, b) => a - b);
  const formattedPrices = prices.map((val) => formatDollars(val / 100));
  if (formattedPrices[0] === formattedPrices[formattedPrices.length - 1])
    return `${formattedPrices[0]}/${resolveTagText(unitTags, priceUnitId)}`;
  return `${formattedPrices[0]}-${
    formattedPrices[formattedPrices.length - 1]
  }/${resolveTagText(unitTags, priceUnitId)}`;
};

export const resolveUnitLabel = (unitArray, unitId) => {
  const unitLabel = resolveTagText(unitArray, unitId);
  return unitLabel !== "ea" ? unitLabel : "";
};

export const formatQuantity = (
  quantity,
  productId,
  unitTagArray,
  productArray
) => {
  const unitLabel = resolveUnitLabel(
    unitTagArray,
    productArray.find((product) => product.ProductID === productId).PriceUnitID
  );
  return `${quantity}${unitLabel.length > 0 ? " " : ""}${unitLabel}`;
};

export const validationExtractLengthErrors = (errors) => {
  const entries = Object.entries(errors);
  console.log(
    entries.map((pair) => `${pair[0]} failed the test ${pair[1].type}`)
  );
  for (const pair of entries) {
    if (pair[1].type === "maxLength") {
      const spacedName = pair[0].replace(/([A-Z])/g, " $1").toLowerCase();
      return `${spacedName.charAt(0).toUpperCase()}${spacedName.slice(
        1
      )} exceeded maximum length.`;
    }
  }
  return "Please fix all errors in form fields to continue.";
};
