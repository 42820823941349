import React from "react";
import styled from "styled-components";
import Column from "../components/atoms/Column";
import Row from "../components/atoms/Row";
import { Colors, device, Typography } from "../components/atoms/Theme";
import PageContainer from "../components/templates/PageContainer";
import { Link, navigate } from "@reach/router";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import ProfileBg from "../assets/images/my-profile-bg.svg";
import FilledStar from "../assets/images/ratings/filled-star.svg";
import EmptyStar from "../assets/images/ratings/empty-star.svg";
import { DataContext } from "providers/DataProvider";
import { AuthContext } from "providers/AuthProvider";
import Space from "components/atoms/Space";
import SimpleTable from "components/atoms/SimpleTable";
import {
  formatDollars,
  formatProductPrice,
  formatQuantity,
  resolveTagText,
  resolveUnitLabel,
} from "utils/helpers";

// Box Components

const Boxes = styled(Row)`
  justify-content: space-around;
  flex: 1;
  flex-wrap: wrap;
`;

const BoxContainer = styled(Column)`
  @media ${device.mobile} {
    width: 80vw;
  }
  @media ${device.desktop} {
    width: 464px;
  }
  background: white;
  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  align-items: center;
  padding: 20px;
  &:hover {
    box-shadow: -8px -8px 16px #ffffff, 8px 8px 16px rgba(0, 0, 0, 0.12);
  }
`;

const BoxTitle = styled(Link)`
  ${Typography.regular}
  padding-bottom: 22px;
  align-self: flex-start;
  &:hover {
    color: #777777;
    text-decoration: underline;
  }
`;

const BoxContentContainer = styled.div`
  background: #ffffff;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
`;

const Box = ({ title, to, children }) => {
  return (
    <BoxContainer>
      <BoxTitle to={to || ""}>{title}</BoxTitle>
      <BoxContentContainer>{children}</BoxContentContainer>
    </BoxContainer>
  );
};

const BoxColumn = styled.div`
  display: grid;
  grid-gap: 40px;
  padding: 20px;
`;

// Orders Box
const MarketOrdersContainer = styled(Column)`
  gap: 14px;
`;

const MarketOrderContainer = styled.div`
  background: #f7f7f7;
  border-radius: 12px;
  padding: 8px 10px;
  font-weight: 400;
`;

const MarketNameContainer = styled(Row)`
  padding: 4px 0;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
`;
const RightCaret = styled(FaCaretRight)`
  cursor: pointer;
`;

const DownCaret = styled(FaCaretDown)`
  cursor: pointer;
`;

const MarketName = styled(Link)`
  ${Typography.regular}
  &:hover {
    color: #777777;
    text-decoration: underline;
  }
`;

const CountContainer = styled.div`
  background: ${Colors.purple};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  margin-left: 6px;
`;

const CountText = styled.div`
  ${Typography.small}
  font-weight: 700;
  color: #ffffff;
`;

const Count = ({ count }) => {
  return (
    <CountContainer>
      <CountText>{count}</CountText>
    </CountContainer>
  );
};

const OrderContainer = styled.div`
  background: #f4f4f4;
  border-radius: 10px;
  padding-left: 26px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const OrderText = styled.span`
  ${Typography.small}
`;

const OrderName = styled(MarketName)`
  ${Typography.small}
  flex: 1;
`;

const OrderSold = styled(OrderText)`
  flex: 2;
  text-align: right;
`;

const OrderProgressContainer = styled.div`
  background: #dddddd;
  border-radius: 10px;
  position: relative;
  flex: 3;
  height: 18px;
`;

const OrderProgressSlider = styled.div`
  border-radius: 10px;
  position: absolute;
  left: 0;
  background: ${Colors.green};
  height: 100%;
  width: ${(props) => props.progress * 100}%;
`;

const OrderProgress = ({ progress }) => {
  return (
    <OrderProgressContainer>
      <OrderProgressSlider progress={progress} />
    </OrderProgressContainer>
  );
};

const MarketOrders = ({ market }) => {
  const { vendor, unitTags, allPendingOrders } = React.useContext(DataContext);
  const [showOrders, setShowOrders] = React.useState(false);

  const products = React.useMemo(
    () =>
      vendor.ProductArray.filter((product) =>
        product.MarketArray.includes(market.MarketID)
      ),
    [vendor.ProductArray, market.MarketID]
  );

  const ordersCount = React.useMemo(
    () =>
      allPendingOrders.filter((order) => order.MarketID === market.MarketID)
        .length,
    [allPendingOrders, market.MarketID]
  );

  return (
    <MarketOrderContainer>
      <MarketNameContainer>
        <IconContainer onClick={() => setShowOrders(!showOrders)}>
          {showOrders ? <DownCaret size={25} /> : <RightCaret size={25} />}
        </IconContainer>
        <MarketName to="/orders" state={{ filter: market.Name }}>
          {market.Name} ({market.Location})
        </MarketName>
        <Count count={ordersCount} />
      </MarketNameContainer>
      {showOrders &&
        products.map((product) => (
          <OrderContainer key={product.ProductID}>
            <OrderName to="/orders" state={{ filter: product.Name }}>
              {product.Name}
            </OrderName>
            <OrderProgress
              progress={
                (product.StockedAmountMap[market.MarketID] -
                  product.AvailableAmountMap[market.MarketID]) /
                product.StockedAmountMap[market.MarketID]
              }
            />
            <OrderSold>
              {product.AllowFractional
                ? (
                    product.StockedAmountMap[market.MarketID] -
                    product.AvailableAmountMap[market.MarketID]
                  ).toFixed(2)
                : product.StockedAmountMap[market.MarketID] -
                  product.AvailableAmountMap[market.MarketID]}
              /{product.StockedAmountMap[market.MarketID]}{" "}
              {resolveUnitLabel(unitTags, product.PriceUnitID)} ordered <br /> (
              {product.SoldAmountMap[market.MarketID] ?? 0}{" "}
              {resolveUnitLabel(unitTags, product.PriceUnitID)} sold total)
            </OrderSold>
          </OrderContainer>
        ))}
    </MarketOrderContainer>
  );
};

const OrdersBox = () => {
  const { vendor } = React.useContext(DataContext);

  return (
    <Box title="Orders" to="/orders">
      <MarketOrdersContainer>
        {vendor.MarketArray.map((market) => (
          <MarketOrders key={market.MarketID} market={market} />
        ))}
      </MarketOrdersContainer>
    </Box>
  );
};

// My Profile Box

const MyProfileContainer = styled(Column)`
  background-image: ${`url(${ProfileBg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
`;

const MyProfileColumn = styled(Column)`
  flex: 1;
`;

const VendorName = styled.span`
  ${Typography.regular}
`;

const Location = styled.span`
  ${Typography.small}
`;

const Description = styled.span`
  font-size: 12px;
`;

const Tagline = styled(Description)`
  font-weight: bold;
`;

// Rating Component

const RatingContainer = styled(Row)`
  align-self: flex-end;
`;

const Rating = ({ stars }) => {
  return (
    <RatingContainer>
      {[...Array(stars).keys()].map((_, index) => (
        <img src={FilledStar} key={index} alt="" />
      ))}
      {[...Array(5 - stars).keys()].map((_, index) => (
        <img src={EmptyStar} key={index} alt="" />
      ))}
    </RatingContainer>
  );
};

// Price Component

const PriceContainer = styled(RatingContainer)``;

const GreenDollar = styled.span`
  color: ${Colors.green};
`;
const GrayDollar = styled.span`
  color: ${Colors.gray};
`;

const Price = ({ price }) => {
  return (
    <PriceContainer>
      {[...Array(price).keys()].map((_, index) => (
        <GreenDollar key={index}>$</GreenDollar>
      ))}
      {[...Array(3 - price).keys()].map((_, index) => (
        <GrayDollar key={index}>$</GrayDollar>
      ))}
    </PriceContainer>
  );
};

const MyProfileBox = () => {
  const { vendor } = React.useContext(DataContext);

  return (
    <Box title="My Profile" to="/me">
      <MyProfileContainer>
        <Row flex="1">
          <MyProfileColumn>
            <VendorName>{vendor.Name}</VendorName>
            <Location>{vendor.Location}</Location>
          </MyProfileColumn>
          <MyProfileColumn>
            {vendor.NumReviews > 0 && (
              <Rating stars={Math.round(vendor.NumStars / vendor.NumReviews)} />
            )}
            <Price price={vendor.PriceLevel} />
          </MyProfileColumn>
        </Row>
        <Space height={10} />
        <Column>
          <Tagline>{vendor.Tagline}</Tagline>
          <Description>{vendor.Description}</Description>
        </Column>
      </MyProfileContainer>
    </Box>
  );
};

// My Products Box

const ProductsContainer = styled.div`
  padding-left: 10px;
  font-weight: 400;
`;

const ProductsTable = ({ products, marketId }) => {
  const { unitTags, categoryTags, allPendingOrders, vendor } =
    React.useContext(DataContext);

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "Name" },
      {
        Header: "Category",
        accessor: "CategoryID",
        Cell: ({ value }) => resolveTagText(categoryTags, value),
      },
      ...(marketId
        ? [
            {
              Header: "Left",
              accessor: "AvailableAmountMap",
              Cell: ({ value, row }) =>
                formatQuantity(
                  value[marketId],
                  row.ProductID,
                  unitTags,
                  vendor.ProductArray
                ),
            },
          ]
        : [
            {
              Header: "Price",
              accessor: "price",
            },
            {
              Header: "Points",
              accessor: "RewardPoints",
            },
          ]),
      {
        Header: "Orders",
        accessor: "ProductID",
        Cell: ({ value }) =>
          allPendingOrders.filter((order) =>
            marketId
              ? order.MarketID === marketId && order.ProductID === value
              : order.ProductID === value
          ).length,
      },
    ],
    [categoryTags, allPendingOrders, marketId, unitTags, vendor.ProductArray]
  );
  const data = React.useMemo(
    () =>
      products.map((product) => ({
        ...product,
        price: formatProductPrice(
          product.PriceMap,
          unitTags,
          product.PriceUnitID
        ),
        onClick: () =>
          marketId
            ? navigate("/orders", { state: { filter: product.Name } })
            : navigate(`/products/edit/${product.ProductID}`),
      })),
    [unitTags, products, marketId]
  );
  return <SimpleTable columns={columns} data={data} />;
};

const MyProductsBox = () => {
  const { vendor } = React.useContext(DataContext);

  return (
    <Box title="My Products" to="/products">
      <ProductsContainer>
        <ProductsTable products={vendor.ProductArray} />
      </ProductsContainer>
    </Box>
  );
};

// Calendar Box
// const CalendarBox = () => {
//   return <Box title="Calendar"></Box>;
// };

// My Markets Box

const Market = ({ market }) => {
  const { vendor } = React.useContext(DataContext);
  const [showOrders, setShowOrders] = React.useState(false);

  const products = React.useMemo(
    () =>
      vendor.ProductArray.filter((product) =>
        product.MarketArray.includes(market.MarketID)
      ),
    [vendor.ProductArray, market.MarketID]
  );

  return (
    <MarketOrderContainer>
      <MarketNameContainer>
        <IconContainer onClick={() => setShowOrders(!showOrders)}>
          {showOrders ? <DownCaret size={25} /> : <RightCaret size={25} />}
        </IconContainer>
        <MarketName to={`/markets/edit/${market.MarketID}`}>
          {market.Name} ({market.Location})
        </MarketName>
      </MarketNameContainer>
      {showOrders && (
        <ProductsTable products={products} marketId={market.MarketID} />
      )}
    </MarketOrderContainer>
  );
};

const MyMarketsBox = () => {
  const { vendor } = React.useContext(DataContext);

  return (
    <Box title="My Markets" to="/markets">
      <MarketOrdersContainer>
        {vendor.MarketArray.map((market) => (
          <Market key={market.MarketID} market={market} />
        ))}
      </MarketOrdersContainer>
    </Box>
  );
};

// My Rewards Box

const RewardsContainer = styled.div`
  padding-left: 10px;
  font-weight: 400;
`;

const MyRewardsBox = () => {
  const { vendor } = React.useContext(DataContext);

  const columns = React.useMemo(
    () => [
      { Header: "Applies to", accessor: "AppliesTo" },
      { Header: "Point cost", accessor: "PointCost" },
      { Header: "Discount", accessor: "Discount" },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      vendor.CouponArray.map((coupon) => ({
        ...coupon,
        AppliesTo: coupon.ProductID
          ? coupon.product.Name
          : coupon.category.TagText,
        Discount: `${
          coupon.IsPercent
            ? `${coupon.Discount * 100}%`
            : formatDollars(coupon.Discount / 100)
        } off`,
        onClick: () =>
          navigate(`/rewards/edit/${coupon.CouponID}`, { state: { coupon } }),
      })),
    [vendor.CouponArray]
  );

  return (
    <Box title="My Rewards" to="/rewards">
      <RewardsContainer>
        <SimpleTable columns={columns} data={data} />
      </RewardsContainer>
    </Box>
  );
};

const Home = () => {
  const { cognitoUser } = React.useContext(AuthContext);
  const { vendor } = React.useContext(DataContext);

  return (
    <PageContainer
      heading={`Welcome, ${cognitoUser.attributes.given_name} ${cognitoUser.attributes.family_name}!`}
      subheading={vendor.Name}
    >
      <Boxes>
        <BoxColumn>
          <OrdersBox />
          <MyProfileBox />
          <MyProductsBox />
        </BoxColumn>
        <BoxColumn>
          {/* <CalendarBox /> */}
          <MyMarketsBox />
          <MyRewardsBox />
        </BoxColumn>
      </Boxes>
    </PageContainer>
  );
};

export default Home;
