import styled from "styled-components";
import Column from "./Column";

const BoxContainer = styled(Column)`
  max-width: ${(props) => (props.width ? props.width : "unset")};
  background: white;
  border-radius: 30px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  height: fit-content;
`;

export default BoxContainer;
