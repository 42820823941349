import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Button from "../components/atoms/Button";
import { formatDollars } from "../utils/helpers";
import { DataContext } from "providers/DataProvider";

const ManageRewards = ({ navigate }) => {
  const { vendor } = React.useContext(DataContext);

  const data = React.useMemo(
    () =>
      vendor.CouponArray.map((coupon) => ({
        ...coupon,
        AppliesTo: coupon.ProductID
          ? coupon.product.Name
          : coupon.category.TagText,
        Type: coupon.ProductID ? "Product" : "Category",
        onClick: () => {
          navigate(`edit/${coupon.CouponID}`, { state: { coupon } });
        },
      })),
    [navigate, vendor.CouponArray]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Applies to",
        accessor: "AppliesTo",
        columnWidth: "40%",
      },
      {
        Header: "Type",
        accessor: "Type",
        columnWidth: "20%",
      },
      {
        Header: "Point cost",
        accessor: "PointCost",
        columnWidth: "15%",
      },
      {
        Header: "Discount",
        accessor: "Discount",
        columnWidth: "15%",
        Cell: ({ row }) => {
          const {
            original: { Discount, IsPercent },
          } = row;
          return IsPercent
            ? `${Discount * 100}%`
            : formatDollars(Discount / 100);
        },
      },
      // {
      //   Header: "Times used",
      //   accessor: "TimesUsed",
      //   columnWidth: "15%",
      // },
    ],
    []
  );
  return (
    <PageContainer heading={"Manage rewards"}>
      <Table
        columns={columns}
        data={data}
        helpText="Click on a row to edit a reward."
      >
        <Button to="new">new reward</Button>
      </Table>
    </PageContainer>
  );
};

export default ManageRewards;
