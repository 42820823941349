import React from "react";
import styled, { css } from "styled-components";
import { Colors } from "./Theme";

const IconButtonContainer = styled.div`
  color: white;
  background: ${(props) => props.color || Colors.red};
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border: 4px solid white;
  width: ${(props) => props.size || 25}px;
  height: ${(props) => props.size || 25}px;
  border-radius: 50%;
  cursor: ${(props) => (props.disabled ? "unset" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.font || 18}px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${(props) =>
    !props.disabled &&
    css`
      &:active {
        border: 4px solid ${(props) => props.color || Colors.red};
      }
    `}
`;

/*
 * Props:
 *      - icon [ReactElement] - icon component ie. FaCamera (from react-icon)
 *      - color [string] - background color
 *      - size [number] - button size
 *      - font [number] - font size
 *      - disabled [boolean] - disable button
 */

const IconButton = (props) => (
  <IconButtonContainer {...props}>
    {React.createElement(props.icon, {})}
  </IconButtonContainer>
);

export default IconButton;
