import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import Row from "../components/atoms/Row";
import { ControlledInput } from "../components/atoms/Input";
import { ControlledSelect } from "../components/atoms/Select";
import Space from "../components/atoms/Space";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm } from "react-hook-form";
import CognitoClient from "../utils/CognitoClient";
import { ToastContext } from "providers/ToastProvider";
import { FORM_LIMITS, PASSWORD_REQS, STATES } from "utils/constants";
import { validationExtractLengthErrors } from "utils/helpers";
import styled from "styled-components";
import Logo from "components/molecules/Logo";
import Link from "components/atoms/Link";
import useKeyPress from "hooks/useKeyPress";

const Container = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5%;
`;

const SignUp = ({ navigate }) => {
  const { showToast } = React.useContext(ToastContext);
  const { control, handleSubmit, watch, setValue } = useForm();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (data) => {
    if (disabled) {
      return;
    }
    if (data.password !== data.confirmPassword) {
      showToast({ message: "Passwords don't match!", type: "error" });
      return;
    }
    setDisabled(true);
    data.phone = CognitoClient.normalizePhone(data.phone);
    try {
      await CognitoClient.signUp(data);
      setDisabled(false);
      navigate("/confirm-email", {
        state: { email: data.email, password: data.password },
      });
    } catch (error) {
      if (error.name === CognitoClient.UsernameExistsException) {
        showToast({
          message:
            "Email already has an account associated with it. Please navigate to sign in.",
          type: "error",
        });
      } else {
        showToast({
          message: `Something went wrong: ${error.message}`,
          type: "error",
        });
      }
    }
    setDisabled(false);
  };

  const onError = (errors) => {
    showToast({
      message: validationExtractLengthErrors(errors),
      type: "error",
    });
  };

  useKeyPress("Enter", { onKeyPress: handleSubmit(onSubmit, onError) });

  return (
    <Container>
      <BoxContainer>
        <Form.Container>
          <Form.Content>
            <Row justify="center">
              <Logo width="300px" />
            </Row>
            <Form.Heading>Create account</Form.Heading>
            <Form.Label>Name</Form.Label>
            <Form.InputGroup>
              <Form.SpacedRow>
                <ControlledInput
                  name="firstName"
                  control={control}
                  placeholder="First"
                  flex="1"
                  rules={{
                    required: true,
                    maxLength: FORM_LIMITS.name,
                    validate: { isName: CognitoClient.isNameValid },
                  }}
                />
                <ControlledInput
                  name="lastName"
                  control={control}
                  placeholder="Last"
                  flex="1"
                  rules={{
                    required: true,
                    maxLength: FORM_LIMITS.name,
                    validate: { isName: CognitoClient.isNameValid },
                  }}
                />
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Contact info</Form.Label>
              <Form.SpacedRow>
                <Column flex={1}>
                  <Form.GrayLabel>Email</Form.GrayLabel>
                  <ControlledInput
                    name="email"
                    control={control}
                    flex="1"
                    rules={{
                      required: true,
                      maxLength: FORM_LIMITS.email,
                      validate: { isEmail: CognitoClient.isEmailValid },
                    }}
                  />
                </Column>
                <Column flex={1}>
                  <Form.GrayLabel>Phone number</Form.GrayLabel>
                  <ControlledInput
                    name="phone"
                    control={control}
                    flex="1"
                    rules={{
                      required: true,
                      maxLength: FORM_LIMITS.phone,
                      validate: {
                        isPhone: (v) => {
                          return CognitoClient.isPhoneValid(
                            CognitoClient.normalizePhone(v)
                          );
                        },
                      },
                    }}
                  />
                </Column>
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Business address</Form.Label>
              <Form.GrayLabel> (will be visible to users)</Form.GrayLabel>
              <ControlledInput
                name="streetAddress"
                control={control}
                placeholder="Street Address"
                width="100%"
                rules={{
                  required: true,
                  maxLength: FORM_LIMITS.streetAddress,
                  validate: {},
                }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.SpacedRow>
                <ControlledInput
                  name="city"
                  control={control}
                  placeholder="City"
                  flex="3"
                  rules={{
                    required: true,
                    maxLength: FORM_LIMITS.city,
                    validate: {},
                  }}
                />
                <ControlledSelect
                  name="state"
                  control={control}
                  placeholder="State"
                  options={STATES}
                  flex="2"
                  rules={{ required: true }}
                  onChange={(val) => {
                    setValue("state", val, { shouldValidate: true });
                  }}
                />
                <ControlledInput
                  name="zip"
                  control={control}
                  placeholder="ZIP"
                  flex="1"
                  rules={{
                    required: true,
                    maxLength: FORM_LIMITS.zip,
                    validate: { isZip: CognitoClient.isZIPValid },
                  }}
                />
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Security</Form.Label>
              <Form.SpacedRow>
                <Column flex={1}>
                  <Form.GrayLabel>Password</Form.GrayLabel>
                  <ControlledInput
                    name="password"
                    type="password"
                    control={control}
                    helpText={PASSWORD_REQS}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStrength: CognitoClient.isPasswordValid,
                      },
                    }}
                  />
                </Column>
                <Column flex={1}>
                  <Form.GrayLabel>Confirm password</Form.GrayLabel>
                  <ControlledInput
                    name="confirmPassword"
                    type="password"
                    control={control}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStrength: CognitoClient.isPasswordValid,
                        passwordsMatch: (v) => {
                          return watch("password") === v;
                        },
                      },
                    }}
                  />
                </Column>
              </Form.SpacedRow>
            </Form.InputGroup>
            <Space height={20} />
            <Form.InfoText>
              Next, we'll...
              <li>Verify your email</li>
              <li>
                Set you up with a Stripe Connect account so you can receive
                payments
              </li>
            </Form.InfoText>
            <Form.Actions>
              <Column align="center" flex="1">
                <Row>
                  <Button
                    disabled={disabled}
                    onClick={handleSubmit(onSubmit, onError)}
                  >
                    continue
                  </Button>
                </Row>
                <Space height={10} />
                {!disabled && (
                  <div>
                    Already have an account?{" "}
                    <Link to="/signin">Sign in here.</Link>
                  </div>
                )}
              </Column>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </Container>
  );
};

export default SignUp;
