import styled, { keyframes } from "styled-components";
import DefaultImage from "./Image";
import carrot from "assets/images/carrot.png";

const spin = keyframes`
    100% { transform: rotate(360deg); } 
`;

const Image = styled(DefaultImage)`
  width: ${(props) => props.width || "200px"};
  transform-origin: 50% 60%;
  animation: ${spin} 1.5s linear infinite;
`;

const LoadingIndicator = ({ size }) => {
  return <Image src={carrot} width={size} />;
};

export default LoadingIndicator;
