import PageContainer from "../components/templates/PageContainer";
import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Row from "../components/atoms/Row";
import {
  decimalInputProps,
  dollarsInputProps,
  InputHelpText,
} from "../components/atoms/Input";
import { ControlledInput } from "../components/atoms/Input";
import { ControlledSelect } from "../components/atoms/Select";
import Space from "../components/atoms/Space";
import { Colors } from "../components/atoms/Theme";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm, useWatch } from "react-hook-form";
import Modal from "../components/molecules/Modal";
import { DataContext } from "../providers/DataProvider";
import { formatDollars, resolveTagText } from "../utils/helpers";
import {
  createCoupon,
  deleteCoupon,
  getItems,
  updateCoupon,
} from "../services/api";
import { ToastContext } from "providers/ToastProvider";

const NewReward = ({ editable, navigate, location: { state }, couponId }) => {
  const { vendor, categoryTags, unitTags, refetchVendor } =
    React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({ mode: "onChange" });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const watchType = useWatch({
    control,
    name: "type",
  });

  React.useEffect(() => {
    (async () => {
      // Load Coupon object from backend (ie. url was directly visited) or from router state
      if (editable) {
        let coupon;
        if (state && state.coupon) {
          coupon = state.coupon;
        } else {
          coupon = (await getItems([couponId])).body.results[couponId];
        }
        const appliesToId = coupon.ProductID || coupon.CategoryID;
        if (coupon.ProductID) {
          const foundProduct = vendor.ProductArray.find(
            (product) => product.ProductID === appliesToId
          );
          if (!foundProduct) {
            showToast({
              message:
                "The product that this reward applies to has been deleted. Consider changing what this reward applies to or delete the reward.",
              type: "error",
              timeout: 15000,
            });
          }
        }
        reset({
          ...coupon,
          value: coupon.IsPercent
            ? coupon.Discount * 100
            : formatDollars(coupon.Discount / 100),
          type: coupon.ProductID ? "Product" : "Category",
          appliesToId,
          unit: coupon.IsPercent ? "percent" : "dollars",
        });
      }
    })();
  }, [editable, reset, state, couponId, showToast, vendor.ProductArray]);

  const appliesToOptions = React.useMemo(() => {
    if (watchType === "Category") {
      // Category options
      const options = {};
      vendor.ProductArray.forEach((product) => {
        options[product.CategoryID] = categoryTags.find(
          (tag) => tag.TagID === product.CategoryID
        ).TagText;
      });
      return Object.keys(options).map((key) => ({
        label: options[key],
        value: key,
      }));
    } else {
      // Product options
      return vendor.ProductArray.map((product) => ({
        label: product.Name,
        value: product.ProductID,
      }));
    }
  }, [vendor.ProductArray, categoryTags, watchType]);

  const onSubmit = (data) => {
    if (watchType === "Product") {
      data.ProductID = data.appliesToId;
      data.CategoryID = null;
    } else {
      data.CategoryID = data.appliesToId;
      data.ProductID = null;
    }

    if (watch("unit") === "percent") data.Discount = data.value / 100;
    else data.Discount = Math.round(parseFloat(data.value.slice(1)) * 100);

    data.VendorID = vendor.VendorID;
    data.IsPercent = data.unit === "percent";

    delete data.value;
    delete data.type;
    delete data.appliesToId;
    delete data.unit;
    delete data.product;
    delete data.category;

    (editable ? updateCoupon(data) : createCoupon(data)).then((response) => {
      if (response.ok) {
        refetchVendor();
        showToast({
          message: `Reward ${editable ? "updated" : "created"}!`,
          type: "success",
        });
        navigate("/rewards");
      } else {
        console.error(response.body);
        showToast({
          message: `Something went wrong: ${response.body.message}`,
          type: "error",
        });
      }
    });
  };

  const onError = (errors) => {
    // showToast({
    //   message: `Validation failed: ${Object.keys(errors).join(", ")}`,
    //   type: "error",
    // });
  };

  const handleDelete = () => {
    deleteCoupon(watch("CouponID")).then((response) => {
      if (response.ok) {
        refetchVendor();
        setShowDeleteConfirmation(false);
        showToast({
          message: "Reward deleted",
          type: "success",
        });
        navigate("/rewards");
      } else {
        console.error(response.body);
        showToast({
          message: `Something went wrong: ${response.body.message}`,
          type: "error",
        });
      }
    });
  };

  return (
    <PageContainer heading={`${editable ? "Edit" : "New"} reward`}>
      <BoxContainer width="1086px">
        <Form.Container>
          <Form.Content>
            <Form.InputGroup>
              <Form.Label>Applies to</Form.Label>
              <Form.SpacedRow>
                <ControlledSelect
                  name="type"
                  control={control}
                  placeholder="Type"
                  options={[
                    { label: "Product", value: "Product" },
                    { label: "Category", value: "Category" },
                  ]}
                  flex="1"
                  rules={{ required: true }}
                  onChange={(val) => {
                    const oldValue = getValues("type");
                    setValue("type", val, { shouldValidate: true });
                    if (oldValue !== val) setValue("appliesToId", "");
                  }}
                />
                <ControlledSelect
                  name="appliesToId"
                  control={control}
                  placeholder="Select..."
                  options={appliesToOptions}
                  rules={{ required: true }}
                  disabled={!watchType}
                  flex="1"
                />
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Point cost</Form.Label>
              <Row align="center">
                <ControlledInput
                  name="PointCost"
                  type="number"
                  control={control}
                  textAlign="right"
                  width="100px"
                  rules={{
                    required: true,
                    min: 1,
                    max: 100,
                  }}
                />
                <Form.Text>per use</Form.Text>
              </Row>
              <Space height={10} />
              <InputHelpText>
                Number of loyalty points a customer must spend to apply this
                reward coupon once. Must be between 1 and 100.
              </InputHelpText>
            </Form.InputGroup>
            <Form.Label>Discount</Form.Label>
            <Row align="center">
              <Form.Text style={{ marginLeft: 0 }}>Customer receives</Form.Text>
              <ControlledInput
                name="value"
                textAlign="right"
                control={control}
                width="100px"
                {...(watch("unit") === "percent"
                  ? decimalInputProps("value", setValue, getValues)
                  : dollarsInputProps("value", setValue, getValues))}
              />
              <Space width={10} />
              <ControlledSelect
                name="unit"
                control={control}
                options={[
                  { label: "dollars", value: "dollars" },
                  { label: "percent", value: "percent" },
                ]}
                width="120px"
                rules={{ required: true }}
                defaultValue="dollars"
                onChange={(val) => {
                  setValue("value", "");
                  clearErrors("value");
                  setValue("unit", val);
                }}
              />
              <Form.Text>
                off one{" "}
                {watchType === "Product" && watch("appliesToId")
                  ? resolveTagText(
                      unitTags,
                      vendor.ProductArray.find(
                        (product) => product.ProductID === watch("appliesToId")
                      ).PriceUnitID
                    )
                  : "unit"}
              </Form.Text>
            </Row>
            <Form.Actions>
              <Row justify="space-between" flex={1}>
                {editable ? (
                  <>
                    <Button
                      color={Colors.gray}
                      onClick={() => setShowDeleteConfirmation(true)}
                    >
                      delete reward
                    </Button>
                    <Modal
                      title="Delete reward?"
                      visible={showDeleteConfirmation}
                      setVisible={setShowDeleteConfirmation}
                      buttons={[
                        {
                          children: "cancel",
                          color: Colors.gray,
                          onClick: () => setShowDeleteConfirmation(false),
                        },
                        {
                          children: "delete",
                          onClick: handleDelete,
                        },
                      ]}
                    >
                      Would you like to delete this reward? This action cannot
                      be undone!
                    </Modal>
                  </>
                ) : (
                  <div />
                )}
                <Row>
                  <Button color={Colors.gray} onClick={() => navigate("../..")}>
                    cancel
                  </Button>
                  <Space width={25} />
                  <Button onClick={handleSubmit(onSubmit, onError)}>
                    save
                  </Button>
                </Row>
              </Row>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default NewReward;
