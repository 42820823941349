import DefaultImage from "../atoms/Image";
import logo from "../../assets/images/logo.svg";
import styled from "styled-components";

const Image = styled(DefaultImage)`
  background: white;
  padding: 10px 14px;
  border-radius: 20px;
  margin-bottom: 25px;
  width: ${(props) => props.width || "85%"};
`;

const Logo = (props) => {
  return <Image src={logo} alt="HarvestHaul Logo" {...props} />;
};

export default Logo;
