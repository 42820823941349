import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Select from "../components/atoms/Select";
import { getOrders } from "services";
import { formatDollars, formatQuantity } from "utils/helpers";
import { DataContext } from "providers/DataProvider";

const AllOrders = () => {
  const { unitTags, vendor } = React.useContext(DataContext);
  const columns = React.useMemo(
    () => [
      {
        Header: "Order #",
        accessor: "OrderNumber",
        columnWidth: "10%",
        Cell: ({ value }) => (value ? value.toString().slice(-4) : ""),
      },
      {
        Header: "Pickup time",
        accessor: "PickupStart",
        columnWidth: "15%",
        Cell: ({ value }) =>
          value
            ? new Date(value).toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              })
            : "",
      },
      {
        Header: "Customer",
        accessor: "CustomerName",
        columnWidth: "18%",
      },
      {
        Header: "Product",
        accessor: "ProductName",
        columnWidth: "35%",
      },
      {
        Header: "Market",
        accessor: "MarketName",
        columnWidth: "35%",
      },
      {
        Header: "Quantity",
        accessor: "Quantity",
        columnWidth: "10%",
        Cell: ({ value, row }) =>
          formatQuantity(
            value,
            row.original.ProductID,
            unitTags,
            vendor.ProductArray
          ),
      },
      {
        Header: "Reward discount",
        accessor: "Discount",
        columnWidth: "8%",
        Cell: ({ value }) => (value ? formatDollars(value / 100) : "n/a"),
      },
      {
        Header: "Subtotal",
        accessor: "Cost",
        columnWidth: "12%",
        Cell: ({ value }) => formatDollars(value / 100),
      },
      {
        Header: "Status",
        accessor: "Status",
        columnWidth: "8%",
      },
      {
        Header: "Type",
        accessor: "IsDelivery",
        Cell: ({ value }) => (value ? "Delivery" : "Pickup"),
        columnWidth: "10%",
      },
    ],
    [unitTags, vendor.ProductArray]
  );

  const [view, setView] = React.useState("all");
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData([]);
    getOrders(view === "all" ? "" : view).then((data) =>
      setData(data.body.orders)
    );
  }, [view]);

  const VIEW_OPTIONS = [
    { value: "all", label: "All orders" },
    { value: "PEND", label: "Pending orders" },
    { value: "DONE", label: "Completed orders" },
    { value: "CNCL", label: "Canceled orders" },
  ];

  return (
    <PageContainer heading="All orders">
      <Table columns={columns} data={data} loading={!data}>
        <Select
          value={view}
          onChange={setView}
          options={VIEW_OPTIONS}
          width={"55%"}
        />
      </Table>
    </PageContainer>
  );
};

export default AllOrders;
