import React from "react";
import styled, { css } from "styled-components";
import { InputField } from "./Input";
import { Colors, Typography } from "./Theme";
import IconButton from "./IconButton";
import Column from "./Column";
import Row from "./Row";
import { FaAngleDown, FaCheck } from "react-icons/fa";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import withControl from "utils/withControl";

const SelectContainer = styled(Column)`
  position: relative;
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => (props.width ? props.width : "unset")};
    `};
`;

const StyledSelect = styled(InputField).attrs({
  as: "div",
})`
  max-height: 50px;
  padding: 6px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  position: relative;
  z-index: ${(props) => (props.open ? 3 : 1)};
`;

const Placeholder = styled.span`
  color: ${Colors.gray};
  font-weight: 500;
`;

const OptionsContainer = styled.div`
  position: absolute;
  min-width: 100px;
  width: 100%;
  top: 20px;
  padding-top: 20px;
  max-height: 400px;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 12px 12px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: ${(props) => (props.open ? 2 : 0)};
`;

const Option = styled(Row)`
  ${Typography.regular}
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  &:hover {
    background: ${Colors.green};
    color: white;
  }
`;

const OptionText = styled.span`
  display: flex;
`;

/*
 * Props:
 *      - placeholder [string] - text to show if value is null
 *      - options [object[]] - array of object representing options with two keys: label and value
 *      - value [any] - default value. leave as null to show placeholder
 *      - onChange [function] - function to set value
 *      - invalid [boolean] - if field should show red error border
 *      - width [string] - width of select. ie. '100px' or '10%'
 *      - multiple [boolean] - allow multiple selections
 *      - showIcon [boolean] - hide dropdown arrow icon. default: true
 *      - disabled [boolean] - if select should be disabled
 */

const Select = React.forwardRef(
  (
    {
      placeholder,
      options,
      value,
      onChange,
      invalid,
      width,
      flex,
      multiple,
      showIcon = true,
      disabled,
      ...props
    },
    ref
  ) => {
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleSelect = (newValue) => {
      if (multiple) {
        if (value && !value.some((selection) => selection.value === newValue))
          onChange((oldValue) => [...oldValue, newValue]);
        else if (
          value &&
          value.some((selection) => selection.value === newValue)
        )
          onChange((oldValue) => oldValue.filter((val) => val !== newValue));
        else onChange([newValue]);
      } else {
        onChange(newValue);
        setOpenMenu(false);
      }
    };

    const isSelected = (optionValue) => {
      if (multiple) {
        return (
          value && value.some((selection) => selection.value === optionValue)
        );
      } else {
        return value === optionValue;
      }
    };

    const displayValue = React.useMemo(() => {
      if (multiple) {
        return value
          .map((val) => {
            const option = options.find((option) => option.value === val);
            return option ? option.label : "";
          })
          .join(", ");
      } else {
        const option = options.find((option) => option.value === value);
        return option ? option.label : "";
      }
    }, [multiple, options, value]);

    return (
      <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
        <SelectContainer flex={flex} width={width} ref={ref} {...props}>
          <StyledSelect
            open={openMenu}
            onClick={() => {
              !disabled && setOpenMenu((open) => !open);
            }}
            invalid={invalid}
            disabled={disabled}
          >
            {!value || value.length === 0 ? (
              <Placeholder>{placeholder}</Placeholder>
            ) : (
              <span>{displayValue}</span>
            )}
            {showIcon && (
              <IconButton
                icon={FaAngleDown}
                color={Colors.green}
                size={20}
                disabled={disabled}
              />
            )}
          </StyledSelect>
          {openMenu && (
            <OptionsContainer width={width} open={openMenu}>
              {options &&
                options.map(({ label, value }) => (
                  <Option
                    key={value}
                    onClick={() => handleSelect(value)}
                    selected={isSelected(value)}
                  >
                    <OptionText>{label}</OptionText>
                    {isSelected(value) && <FaCheck />}
                  </Option>
                ))}
            </OptionsContainer>
          )}
        </SelectContainer>
      </ClickAwayListener>
    );
  }
);

export const ControlledSelect = withControl(Select);

export default Select;
