import PageContainer from "../components/templates/PageContainer";
import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import Row from "../components/atoms/Row";
import { ControlledInput } from "../components/atoms/Input";
import { ControlledSelect } from "../components/atoms/Select";
import { Colors } from "../components/atoms/Theme";
import Space from "../components/atoms/Space";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm } from "react-hook-form";
import CognitoClient from "../utils/CognitoClient";
import { AuthContext } from "providers/AuthProvider";
import { ToastContext } from "providers/ToastProvider";
import { FORM_LIMITS, PASSWORD_REQS, STATES } from "utils/constants";
import { validationExtractLengthErrors } from "utils/helpers";

const AccountUpdate = ({ navigate }) => {
  const { cognitoUser, loadUser } = React.useContext(AuthContext);
  const { showToast } = React.useContext(ToastContext);
  const { control, handleSubmit, watch, setValue } = useForm();
  const [updating, setUpdating] = React.useState("none");
  const [disabled, setDisabled] = React.useState(false);
  const [address, setAddress] = React.useState(undefined);
  const [addressStr, setAddressStr] = React.useState("");
  const [phoneStr, setPhoneStr] = React.useState("");

  React.useEffect(() => {
    loadUser();
  }, [loadUser]);

  React.useEffect(() => {
    const address = JSON.parse(cognitoUser.attributes.address);
    setAddress(address);
    setAddressStr(CognitoClient.strFromOpenIdDict(address));
  }, [cognitoUser.attributes.address]);

  React.useEffect(() => {
    const phone = cognitoUser.attributes.phone_number;
    if (phone)
      setPhoneStr(
        `(${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8)}`
      );
  }, [cognitoUser.attributes.phone_number]);

  const submitUpdate = async (data) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    data.phone = CognitoClient.normalizePhone(data.phone);
    try {
      const updated = await CognitoClient.processNewAttributes(data);
      loadUser();
      showToast({
        message: `The following were updated: ${updated}.`,
        type: "success",
      });
      setUpdating("none");
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  const submitPassword = async (data) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    if (data.newPassword !== data.confirmPassword) {
      showToast({
        message: "New password not the same as confirm password.",
        type: "error",
      });
      setDisabled(false);
      return;
    }
    try {
      await CognitoClient.changePassword(data);
      showToast({
        message: `Password was successfully changed!`,
        type: "success",
      });
      setUpdating("none");
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  const onError = (errors) => {
    showToast({
      message: validationExtractLengthErrors(errors),
      type: "error",
    });
  };

  return (
    <PageContainer
      heading={
        updating === "none"
          ? "View account"
          : updating === "account"
          ? "Update account"
          : "Change password"
      }
    >
      <BoxContainer width="1086px">
        <Form.Container>
          {address === undefined ? (
            <Form.Content>
              <Form.Label>Loading...</Form.Label>
            </Form.Content>
          ) : updating === "none" ? (
            <Form.Content>
              <Form.SpacedRow align="center">
                <Form.Label>Name: </Form.Label>
                <Form.GrayLabel>
                  {cognitoUser.attributes.given_name}{" "}
                  {cognitoUser.attributes.family_name}
                </Form.GrayLabel>
              </Form.SpacedRow>
              <Form.SpacedRow align="center">
                <Form.Label>Email: </Form.Label>
                <Form.GrayLabel>{cognitoUser.attributes.email}</Form.GrayLabel>
              </Form.SpacedRow>
              <Form.SpacedRow align="center">
                <Form.Label>Phone: </Form.Label>
                <Form.GrayLabel>{phoneStr}</Form.GrayLabel>
              </Form.SpacedRow>
              <Form.SpacedRow align="center">
                <Form.Label>Address: </Form.Label>
                <Space width={20} />
                <Form.GrayLabel>{addressStr}</Form.GrayLabel>
              </Form.SpacedRow>
              <Column>
                <Button
                  disabled={disabled}
                  onClick={() => setUpdating("account")}
                >
                  update account info
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => setUpdating("password")}
                >
                  change password
                </Button>
                <Button
                  color={Colors.gray}
                  disabled={disabled}
                  onClick={() => navigate("/me")}
                >
                  back to me
                </Button>
              </Column>
            </Form.Content>
          ) : updating === "account" ? (
            <Form.Content>
              <Form.Label>Name</Form.Label>
              <Form.InputGroup>
                <Form.SpacedRow>
                  <ControlledInput
                    name="firstName"
                    control={control}
                    placeholder="First"
                    defaultValue={cognitoUser.attributes.given_name}
                    flex="1"
                    rules={{
                      maxLength: FORM_LIMITS.name,
                      validate: {
                        isName: (v) => {
                          return !v || CognitoClient.isNameValid(v);
                        },
                      },
                    }}
                  />
                  <ControlledInput
                    name="lastName"
                    control={control}
                    placeholder="Last"
                    defaultValue={cognitoUser.attributes.family_name}
                    flex="1"
                    rules={{
                      maxLength: FORM_LIMITS.name,
                      validate: {
                        isName: (v) => {
                          return !v || CognitoClient.isNameValid(v);
                        },
                      },
                    }}
                  />
                </Form.SpacedRow>
              </Form.InputGroup>
              <Form.InputGroup>
                <Form.Label>Contact info</Form.Label>
                <Form.SpacedRow>
                  <ControlledInput
                    name="email"
                    control={control}
                    placeholder="Email"
                    defaultValue={cognitoUser.attributes.email}
                    flex="1"
                    rules={{
                      maxLength: FORM_LIMITS.email,
                      validate: {
                        isEmail: (v) => {
                          return !v || CognitoClient.isEmailValid(v);
                        },
                      },
                    }}
                  />
                  <ControlledInput
                    name="phone"
                    control={control}
                    placeholder="Phone number"
                    defaultValue={phoneStr}
                    flex="1"
                    rules={{
                      maxLength: FORM_LIMITS.phone,
                      validate: {
                        isPhone: (v) => {
                          return (
                            !v ||
                            CognitoClient.isPhoneValid(
                              CognitoClient.normalizePhone(v)
                            )
                          );
                        },
                      },
                    }}
                  />
                </Form.SpacedRow>
              </Form.InputGroup>
              <Form.InputGroup>
                <Form.Label>Business Address</Form.Label>
                <Form.GrayLabel> (is visible to users)</Form.GrayLabel>
                <ControlledInput
                  name="streetAddress"
                  control={control}
                  placeholder="Street Address"
                  defaultValue={address.street_address}
                  width="100%"
                  rules={{
                    maxLength: FORM_LIMITS.streetAddress,
                    validate: {},
                  }}
                />
              </Form.InputGroup>
              <Form.InputGroup>
                <Form.SpacedRow>
                  <ControlledInput
                    name="city"
                    control={control}
                    placeholder="City"
                    defaultValue={address.locality}
                    flex="3"
                    rules={{
                      maxLength: FORM_LIMITS.city,
                      validate: {},
                    }}
                  />
                  <ControlledSelect
                    name="state"
                    control={control}
                    placeholder="State"
                    defaultValue={address.region}
                    options={STATES}
                    flex="3"
                    onChange={(val) => {
                      setValue("state", val, { shouldValidate: true });
                    }}
                  />
                  <ControlledInput
                    name="zip"
                    control={control}
                    placeholder="ZIP"
                    flex="1"
                    defaultValue={address.postal_code}
                    rules={{
                      maxLength: FORM_LIMITS.zip,
                      validate: {
                        isZip: (v) => {
                          return !v || CognitoClient.isZIPValid(v);
                        },
                      },
                    }}
                  />
                </Form.SpacedRow>
              </Form.InputGroup>
              <Form.GrayLabel>
                To not update a field, leave it as-is or blank.
              </Form.GrayLabel>
              <Row gap={10} justify="center">
                <Button
                  disabled={disabled}
                  color={Colors.gray}
                  onClick={() => setUpdating("none")}
                >
                  cancel
                </Button>
                <Button
                  disabled={disabled}
                  onClick={handleSubmit(submitUpdate, onError)}
                >
                  update
                </Button>
              </Row>
            </Form.Content>
          ) : (
            <Form.Content>
              <Form.InputGroup>
                <Column flex={1}>
                  <Form.Label>Old password</Form.Label>
                  <ControlledInput
                    name="oldPassword"
                    type="password"
                    control={control}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStength: CognitoClient.isPasswordValid,
                      },
                    }}
                  />
                </Column>
                <Space height={10} />
                <Column flex={1}>
                  <Form.Label>New password</Form.Label>
                  <ControlledInput
                    name="newPassword"
                    type="password"
                    control={control}
                    helpText={PASSWORD_REQS}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStength: CognitoClient.isPasswordValid,
                      },
                    }}
                  />
                </Column>
                <Space height={10} />
                <Column flex={1}>
                  <Form.Label>Confirm new password</Form.Label>
                  <ControlledInput
                    name="confirmPassword"
                    type="password"
                    control={control}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStength: CognitoClient.isPasswordValid,
                        passwordsMatch: (v) => {
                          return watch("newPassword") === v;
                        },
                      },
                    }}
                  />
                </Column>
              </Form.InputGroup>
              <Row justify="center" gap={10}>
                <Button
                  disabled={disabled}
                  onClick={handleSubmit(submitPassword, onError)}
                >
                  submit password
                </Button>
                <Button
                  disabled={disabled}
                  color={Colors.gray}
                  onClick={() => setUpdating("none")}
                >
                  cancel
                </Button>
              </Row>
            </Form.Content>
          )}
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default AccountUpdate;
