import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Button from "../components/atoms/Button";

const JoinMarket = () => {
  const data = React.useMemo(() => [], []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Market name",
        accessor: "name",
        columnWidth: "40%",
      },
      {
        Header: "Location",
        accessor: "location",
        columnWidth: "20%",
      },
      {
        Header: "Hours",
        accessor: "hours",
        columnWidth: "20%",
      },
      {
        Header: "",
        accessor: "joinLink",
        isLink: true,
        linkText: "Request to join",
        columnWidth: "20%",
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <PageContainer heading={"Join a market"}>
      <Table columns={columns} data={data}>
        <Button to="..">cancel</Button>
      </Table>
    </PageContainer>
  );
};

export default JoinMarket;
