import React from "react";
import PageContainer from "../components/templates/PageContainer";
import QrReader from "react-qr-reader";
import BoxContainer from "components/atoms/BoxContainer";
import styled from "styled-components";
import { Colors, Typography } from "components/atoms/Theme";
import Space from "components/atoms/Space";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import { ToastContext } from "providers/ToastProvider";
import Column from "components/atoms/Column";
import { rewardPoints } from "services";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
`;

const Heading = styled.div`
  ${Typography.heading}
  text-align:center;
`;

const Green = styled.span`
  color: ${Colors.green};
`;

const Onsite = ({ paramUserId, navigate }) => {
  const { showToast } = React.useContext(ToastContext);
  const [userId, setUserId] = React.useState();
  const [customerName, setCustomerName] = React.useState();
  const [points, setPoints] = React.useState();

  const handleError = () =>
    showToast({
      message: "Please allow access to the camera.",
      type: "error",
    });

  const handleScan = React.useCallback((data) => {
    if (data) {
      var url = new URL(data);
      var params = new URLSearchParams(url.search);
      const splitPath = url.pathname.split("/");
      setUserId(splitPath[splitPath.length - 1]);
      setCustomerName(params.get("name"));
    }
  }, []);

  const handleDone = () => {
    rewardPoints(userId, points).then((response) => {
      if (response.ok) {
        const newBalance = response.body.newPointsBalance;
        showToast({
          message: `Points rewarded! ${customerName} now has ${newBalance} points.`,
          type: "success",
        });
        setPoints();
        navigate("/onsite"); // clear params
      } else {
        showToast({
          message: `Something went wrong rewarding points: ${response.body.message}`,
          type: "error",
        });
      }
      setUserId();
      setCustomerName();
    });
  };

  React.useEffect(() => {
    if (paramUserId) {
      setUserId(paramUserId);
      const params = new URLSearchParams(window.location.search);
      setCustomerName(params.get("name"));
    }
  }, [paramUserId, handleScan]);

  return (
    <PageContainer heading="Give reward points">
      <BoxContainer width="1086px">
        <ContentContainer align="center">
          {userId ? (
            <>
              <Heading>
                How many points should we give to <Green>{customerName}</Green>?
              </Heading>
              <Space height={20} />
              <Column>
                <Input
                  value={points}
                  onChange={(val) => setPoints(parseInt(val))}
                  type="number"
                />
                <Space height={20} />
                <Button onClick={handleDone}>give reward points</Button>
              </Column>
            </>
          ) : (
            <>
              {/* Scan order  */}
              <Heading>Scan a customer's QR code</Heading>
              <Space height={20} />
              <QrReader
                delay={500}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "90%" }}
              />
            </>
          )}
        </ContentContainer>
      </BoxContainer>
    </PageContainer>
  );
};

export default Onsite;
