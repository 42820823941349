import PageContainer from "../components/templates/PageContainer";
import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Space from "../components/atoms/Space";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import Link from "../components/atoms/Link";
import { useForm } from "react-hook-form";
import { getStripeCreateLink } from "services";
import { navigate } from "@reach/router";
import { DataContext } from "providers/DataProvider";
import { ToastContext } from "providers/ToastProvider";
import { STRIPE_EXPLANATION } from "utils/constants";
import Row from "components/atoms/Row";

const StripeSetup = () => {
  const { refetchVendor } = React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);
  const { handleSubmit } = useForm();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async () => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      // stripe docs say to not show the link to user, redirect instead
      const { ok, body } = await getStripeCreateLink();
      if (ok) {
        setDisabled(false);
        window.location.href = body.url;
      } else if (body.message === "StripeAccountAlreadyCompleted") {
        showToast({
          message: "Stripe setup already complete.",
          type: "success",
        });
        await refetchVendor();
        setDisabled(false);
        navigate("/");
      } else {
        showToast({
          message: `Something went wrong: ${body.message}`,
          type: "error",
        });
      }
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  return (
    <PageContainer heading="Stripe account setup" logo={false}>
      <BoxContainer width="1086px">
        <Form.Container>
          <Form.Content>
            <Row justify="center">
              <Form.Heading>
                Let's set you up with a Stripe Connect Account!
              </Form.Heading>
            </Row>
            <Form.InfoText>{STRIPE_EXPLANATION.payouts}</Form.InfoText>
            <Form.Actions>
              <Link to="/confirm-stripe">already set up Stripe?</Link>
              <Space width={20} />
              <Button
                disabled={disabled}
                onClick={handleSubmit(onSubmit, undefined)}
              >
                continue
              </Button>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default StripeSetup;
