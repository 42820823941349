import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Row from "../components/atoms/Row";
import { ControlledInput } from "../components/atoms/Input";
import Space from "../components/atoms/Space";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm } from "react-hook-form";
import CognitoClient from "../utils/CognitoClient";
import { useLocation } from "@reach/router";
import { AuthContext } from "providers/AuthProvider";
import { ToastContext } from "providers/ToastProvider";
import Logo from "components/molecules/Logo";
import styled from "styled-components";
import Column from "components/atoms/Column";

const Container = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ConfirmEmail = ({ navigate }) => {
  const { loadUser } = React.useContext(AuthContext);
  const { showToast } = React.useContext(ToastContext);
  const { control, handleSubmit } = useForm();
  const [disabled, setDisabled] = React.useState(true);
  const { state } = useLocation();

  React.useEffect(() => {
    if (
      state === null ||
      state.email === undefined ||
      !CognitoClient.isEmailValid(state.email)
    ) {
      showToast({
        message: "Invalid request, please sign in first.",
        type: "error",
      });
      navigate("/");
    } else {
      setDisabled(false);
    }
  }, [navigate, showToast, state]);

  const onSubmit = async (data) => {
    if (disabled || state.email === undefined) {
      return;
    }
    setDisabled(true);
    var success = false;
    try {
      await CognitoClient.confirmEmail({ ...data, email: state.email });
      showToast({ message: "Email confirmed.", type: "success" });
      success = true;
    } catch (error) {
      showToast({
        message: `Failed to confirm email: ${error.message}`,
        type: "error",
      });
      setDisabled(false);
    }
    if (success) {
      try {
        await CognitoClient.signIn({
          email: state.email,
          password: state.password,
        });
        state.password = undefined;
        loadUser(true);
        setDisabled(false);
        navigate("/");
      } catch (error) {
        state.password = undefined;
        console.log(error);
        showToast({
          message:
            "Email was confirmed, but session is expired. Please sign in again (you will be redirected).",
          type: "error",
        });
        await new Promise((v) => setTimeout(v, 3000));
        setDisabled(false);
        navigate("/");
      }
    }
  };

  const resend = async () => {
    if (disabled || state.email === undefined) {
      return;
    }
    setDisabled(true);
    try {
      await CognitoClient.resendConfirmationCode({ email: state.email });
      showToast({ message: "Confirmation code resent.", type: "success" });
    } catch (error) {
      showToast({
        message: `Failed to resend code: ${error}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  const onError = (errors) => {
    console.log(
      Object.entries(errors).map(
        (pair) => pair[0] + " failed the test " + pair[1].type
      )
    );
    showToast({
      message: "Please fix all errors in form fields to continue.",
      type: "error",
    });
  };

  return (
    <Container>
      <BoxContainer>
        <Form.Container>
          <Form.Content>
            <Row justify="center">
              <Logo width="300px" />
            </Row>
            <Form.Heading>Let's confirm your email!</Form.Heading>
            <Form.GrayLabel>
              Check your inbox. We sent you a confirmation code.
            </Form.GrayLabel>
            <Form.InputGroup>
              <Form.Label>code</Form.Label>
              <ControlledInput
                name="code"
                control={control}
                width="100%"
                maxChars={6}
                rules={{
                  required: true,
                  minLength: 6,
                  pattern: /^\d+$/,
                }}
              />
            </Form.InputGroup>
            <Form.Actions>
              <Row>
                <Button disabled={disabled} onClick={resend}>
                  resend code
                </Button>
                <Space width={40} />
                <Button
                  disabled={disabled}
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  confirm
                </Button>
              </Row>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </Container>
  );
};

export default ConfirmEmail;
