import styled, { css } from "styled-components";
import { Colors, Typography } from "./Theme";
import { Link } from "@reach/router";

export const BaseButton = styled.div`
  font-weight: 500;
  ${Typography.label}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  padding: 4px 30px;
  background: ${(props) => props.color || Colors.red};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  margin: 6px 0;
  flex-grow: 0;
  ${(props) =>
    !props.disabled
      ? css`
          cursor: pointer;
          &:active {
            border: 6px solid ${(props) => props.color || Colors.red};
          }
          &:hover,
          &:active {
            text-decoration: underline;
          }
        `
      : css`
          opacity: 0.5;
        `}
`;

/*
 * Props:
 *    - fontSize? [number] - font size of text
 *    - color? [string] - background color of button
 *    - to? [string] - url/path to redirect if clicked. same as href on an anchor tag.
 *        - if included, you can also use these other props: https://reach.tech/router/api/Link
 *    - onClick? [function] - function to run when clicked
 *    - disabled? [boolean] - if button should be disabled
 */

const Button = (props) => {
  if (props.to)
    return (
      <Link {...props}>
        <BaseButton {...props} onClick={!props.disabled ? props.onClick : null}>
          {props.children}
        </BaseButton>
      </Link>
    );
  return (
    <BaseButton {...props} onClick={!props.disabled ? props.onClick : null}>
      {props.children}
    </BaseButton>
  );
};

export default Button;
