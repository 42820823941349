import React from "react";
import styled, { css } from "styled-components";
import withControl from "utils/withControl";
import Column from "./Column";
import Row from "./Row";
import { Colors, Typography } from "./Theme";

const InputContainer = styled(Column)`
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => (props.width ? props.width : "unset")};
    `};
`;

export const InputHelpText = styled.span`
  ${Typography.small}
  font-weight: 400;
  line-height: 19px;
  color: #a3a3a3;
  align-self: flex-start;
  padding-top: 8px;
`;

const InputInfoContainer = styled(Row)`
  position: relative;
`;

const InputCharCount = styled(InputHelpText)`
  position: absolute;
  right: 0;
`;

export const InputField = styled.input`
  ${Typography.regular}
  background: #f9f9f9;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: ${(props) => (props.variant === "compact" ? "12px" : "30px")};
  border: none;
  font-weight: 400;
  line-height: 28px;
  font-family: Rubik;
  color: ${(props) => (props.disabled ? Colors.textLight : "black")};
  outline: none;
  padding: ${(props) => (props.variant === "compact" ? "2px 6px" : "6px 16px")};
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${(props) =>
    props.invalid &&
    css`
      border: 2px solid #ff0000;
    `}
  &::-webkit-input-placeholder {
    color: ${Colors.gray};
    font-weight: 500;
  }
  /* hide arrows for number input */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TextAreaField = styled(InputField).attrs({
  as: "textarea",
})`
  resize: none;
`;

/*
 * Props:
 *      - value [any] - default value
 *      - onChange [function] - function to set value on change
 *      - onBlur [function] - function to run on input blur
 *      - invalid [boolean] - if field should show red error border
 *      - showCounter [boolean] - show char counter
 *      - maxChars [number] - max chars to display on char counter
 *      - helpText [string] - info text to display under input
 *      - placeholder [string] - value to show when no input
 *      - numLines [number] - rows to show if a textarea is desired
 *      - type [string] - input type (ie. text, number, etc). default: text
 *      - width [string] - width of input (ie. '100px' or '30%')
 *      - textAlign [string] - text alignment of input
 *      - disabled [boolean] - if input should be disabled
 *      - autoFocus [boolean] - if input should autofocus
 *      - variant [string] - variant of input - 'compact'
 */

const Input = React.forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      invalid,
      showCounter,
      maxChars,
      helpText,
      placeholder,
      numLines,
      type,
      width,
      flex,
      textAlign,
      disabled,
      autoFocus,
      ...inputProps
    },
    ref
  ) => {
    const handleChange = (event) => {
      let value = event.target.value;
      if (maxChars && maxChars && value.length > maxChars) return;
      if (type === "number" && value.length > 0) {
        value = parseFloat(value);
      }
      onChange(value);
    };

    return (
      <InputContainer flex={flex} width={width}>
        {numLines ? (
          <TextAreaField
            ref={ref}
            rows={numLines}
            onChange={handleChange}
            value={value}
            placeholder={placeholder}
            invalid={invalid}
            textAlign={textAlign}
            disabled={disabled}
            autoFocus={autoFocus}
            onBlur={onBlur}
            {...inputProps}
          />
        ) : (
          <InputField
            ref={ref}
            onChange={handleChange}
            value={value}
            placeholder={placeholder}
            type={type}
            invalid={invalid}
            textAlign={textAlign}
            disabled={disabled}
            autoFocus={autoFocus}
            onBlur={onBlur}
            onWheel={(e) => e.target.blur()}
            {...inputProps}
          />
        )}
        <InputInfoContainer>
          {helpText && <InputHelpText>{helpText}</InputHelpText>}
          {(showCounter || showCounter === undefined) &&
            maxChars !== undefined &&
            value !== undefined && (
              <InputCharCount>
                {value.length}/{maxChars}
              </InputCharCount>
            )}
        </InputInfoContainer>
      </InputContainer>
    );
  }
);

export const ControlledInput = withControl(Input);

export const dollarsInputProps = (fieldName, setValue, getValues) => ({
  placeholder: "$0.00",
  onChange: (value) => {
    if (value.charAt(0) === "$" && value.length === 1) {
      // Clear field if last digit removed
      setValue(fieldName, "");
      return;
    }
    if (value.charAt(0) === "$") value = value.slice(1);
    if (
      !isNaN(value) &&
      (value.match(/\./g) || []).length <= 1 // Only one decimal
    ) {
      setValue(fieldName, "$" + value, { shouldValidate: true });
    }
  },
  onBlur: () => {
    const value = getValues(fieldName);
    if (value && value.length > 1)
      setValue(fieldName, "$" + parseFloat(value.slice(1)).toFixed(2));
  },
  rules: {
    required: true,
    validate: {
      inRange: (v) =>
        parseFloat(v.slice(1)) >= 0.01 && parseFloat(v.slice(1)) <= 999.99,
    },
  },
});

export const decimalInputProps = (fieldName, setValue, getValues) => ({
  placeholder: "0",
  onChange: (value) => {
    if (
      !isNaN(value) &&
      (value.toString().match(/\./g) || []).length <= 1 // Only one decimal
    ) {
      setValue(fieldName, value, { shouldValidate: true });
    }
  },
  onBlur: () => {
    const value = getValues(fieldName);
    if (value && value.length) setValue(fieldName, parseFloat(value).toFixed(1));
  },
  type: "number",
  rules: {
    required: true,
    min: 0,
    max: 100,
    validate: {},
  },
});

export default Input;
