import PageContainer from "../components/templates/PageContainer";
import React from "react";
import styled from "styled-components";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import Row from "../components/atoms/Row";
import Table from "../components/atoms/Table";
import {
  decimalInputProps,
  dollarsInputProps,
  InputHelpText,
} from "../components/atoms/Input";
import { ControlledInput } from "../components/atoms/Input";
import { ControlledSelect } from "../components/atoms/Select";
import Space from "../components/atoms/Space";
import { PRODUCT_ORDER_OPTIONS } from "../utils/constants";
import { Colors, Typography } from "../components/atoms/Theme";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { ControlledCoverPhoto } from "../components/molecules/CoverPhoto";
import { ControlledPhotos } from "../components/molecules/Photos";
import { useForm, useWatch } from "react-hook-form";
import Checkbox from "components/atoms/Checkbox";
import { useController } from "react-hook-form";
import Modal from "components/molecules/Modal";
import { FaListAlt } from "react-icons/fa";
import { DataContext } from "../providers/DataProvider";
import {
  getItems,
  createProduct,
  updateProduct,
  deleteProduct,
  deleteProductImages,
  uploadProductImages,
} from "services";
import { ControlledTags } from "components/molecules/Tags";
import { ToastContext } from "providers/ToastProvider";
import useResponsive from "hooks/useResponsive";
import { formatDollars } from "utils/helpers";

const PointsUnitLabel = styled.span`
  ${Typography.regular}
  font-weight: 400;
`;

const HelpTextLink = styled(InputHelpText).attrs({
  as: "a",
  target: "_blank",
})`
  text-decoration: underline;
`;

const SetAllButton = styled(FaListAlt)`
  color: ${Colors.red};
  cursor: pointer;
  &:hover,
  &:active {
    color: ${Colors.darkRed};
  }
`;

const SetAllModal = ({ title, body, inputProps, callback }) => {
  const [visible, setVisible] = React.useState(false);
  const { control, setValue, getValues, watch } = useForm();

  return (
    <>
      <SetAllButton onClick={() => setVisible(true)} />
      <Modal
        title={title}
        visible={visible}
        setVisible={setVisible}
        buttons={[
          {
            children: "cancel",
            color: Colors.gray,
            onClick: () => setVisible(false),
          },
          {
            children: "OK",
            onClick: () => {
              callback(getValues("input"));
              setVisible(false);
            },
            disabled: !watch("input"),
          },
        ]}
      >
        <Column align="center">
          {body}
          <Space height={10} />
          <ControlledInput
            control={control}
            name="input"
            width="40%"
            {...inputProps(setValue, getValues)}
          />
        </Column>
      </Modal>
    </>
  );
};

const StockToAllocateInput = ({
  row: {
    original: { MarketID },
  },
  handleChange,
  inputValue,
}) => {
  const { control, setValue, getValues, reset } = useForm({ mode: "onChange" });
  const inputProps = decimalInputProps("stock", setValue, getValues);

  React.useEffect(() => {
    reset({
      stock: MarketID in inputValue ? inputValue[MarketID].stockToAllocate : "",
    });
  }, [inputValue, MarketID, reset]);

  return (
    <>
      <ControlledInput
        control={control}
        name="stock"
        variant="compact"
        width="80%"
        disabled={!(MarketID in inputValue)}
        {...inputProps}
        onBlur={() => {
          inputProps.onBlur();
          handleChange(
            MarketID,
            "stockToAllocate",
            parseFloat(getValues("stock"))
          );
        }}
        rules={{
          required: true,
          min: 0,
          validate: {},
        }}
      />
      <Space height={3} />
    </>
  );
};

const PriceInput = ({
  row: {
    original: { MarketID },
  },
  handleChange,
  inputValue,
}) => {
  // useForm so we can use dollarsInputProps
  const { control, setValue, getValues, reset } = useForm({ mode: "onChange" });
  const inputProps = dollarsInputProps("price", setValue, getValues);

  React.useEffect(() => {
    reset({ price: MarketID in inputValue ? inputValue[MarketID].price : "" });
  }, [inputValue, MarketID, reset]);

  return (
    <>
      <ControlledInput
        control={control}
        name="price"
        variant="compact"
        width="80%"
        disabled={!(MarketID in inputValue)}
        {...inputProps}
        onBlur={() => {
          inputProps.onBlur();
          handleChange(MarketID, "price", getValues("price"));
        }}
      />
      <Space height={3} />
    </>
  );
};

const PricingAndAvailability = ({ control, name, unit }) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: {
      validate: (value) =>
        Object.values(value)
          .map((val) => "price" in val && "stockToAllocate" in val)
          .every((x) => x) ||
        "Make sure 'Price' and 'Stock to allocate' is set for all products marked as available",
    },
    defaultValue: {},
  });
  const { vendor } = React.useContext(DataContext);
  const markets = React.useMemo(
    () =>
      vendor.MarketArray.map(({ MarketID, Name, Location }) => ({
        MarketID,
        Name,
        Location,
      })),
    [vendor.MarketArray]
  );
  const allMarkets = markets.map((market) => market.MarketID);

  const allMarketsSelected = React.useMemo(
    () => Object.keys(value).length === allMarkets.length,
    [value, allMarkets.length]
  );

  const columns = React.useMemo(() => {
    let newValue = { ...value };

    const handleSelect = (market) => {
      if (market in value) {
        delete newValue[market];
      } else {
        newValue[market] = {};
      }
      onChange(newValue);
    };

    const handleSelectAll = (val) => {
      if (val)
        onChange(
          allMarkets.reduce((acc, market) => {
            acc[market] = {};
            return acc;
          }, {})
        );
      else onChange({});
    };

    const setAllStockToAllocate = (stockToAllocate) => {
      const newValue = { ...value };
      Object.keys(newValue).forEach(
        (market) => (newValue[market].stockToAllocate = stockToAllocate)
      );
      onChange(newValue);
    };

    const setAllPrice = (price) => {
      const newValue = { ...value };
      Object.keys(newValue).forEach(
        (market) => (newValue[market].price = price)
      );
      onChange(newValue);
    };

    return [
      {
        Header: "Market name",
        accessor: "Name",
        columnWidth: "30%",
      },
      {
        Header: "Location",
        accessor: "Location",
        columnWidth: "20%",
      },
      {
        Header: () => (
          <Row align="center">
            <Checkbox value={allMarketsSelected} onChange={handleSelectAll} />
            <Space width={12} />
            Select all
          </Row>
        ),
        Cell: ({
          row: {
            original: { MarketID },
          },
        }) => (
          <Row align="center">
            <Checkbox
              value={MarketID in value}
              onChange={() => handleSelect(MarketID)}
            />
            <Space width={12} />
            Available
          </Row>
        ),
        id: "available",
        columnWidth: "15%",
        disableSortBy: true,
      },
      {
        Header: () => (
          <Row align="center">
            Price
            <Space width={10} />
            {Object.keys(value).length > 0 && (
              <SetAllModal
                title="Set price for all"
                body={"Set price for all selected markets at once."}
                inputProps={(setValue, getValues) =>
                  dollarsInputProps("input", setValue, getValues)
                }
                callback={setAllPrice}
              />
            )}
          </Row>
        ),
        Cell: PriceInput,
        id: "price",
        columnWidth: "20%",
        disableSortBy: true,
      },
      {
        Header: () => (
          <Row align="center">
            Stock to allocate {unit ? `(${unit})` : ""}
            <Space width={10} />
            {Object.keys(value).length > 0 && (
              <SetAllModal
                title="Set stock to allocate for all"
                body={`Set stock to allocate for all selected markets at once. ${
                  unit ? `Unit: ${unit}` : ""
                }`}
                inputProps={(setValue, getValues) => ({
                  ...decimalInputProps("input", setValue, getValues),
                  rules: {
                    required: true,
                    min: 0,
                    validate: {},
                  },
                })}
                callback={setAllStockToAllocate}
              />
            )}
          </Row>
        ),
        Cell: StockToAllocateInput,
        id: "stockToAllocate",
        columnWidth: "22%",
        disableSortBy: true,
      },
    ];
  }, [value, unit, allMarkets, onChange, allMarketsSelected]);

  const handleChange = (market, key, fieldValue) => {
    let newValue = { ...value };
    newValue[market][key] = fieldValue;
    onChange(newValue);
  };

  return (
    <Table
      columns={columns}
      data={markets}
      searchable={false}
      width="95%"
      helpText="Select the farmers' markets where you sell this product, and set the price and amount of this product that you want to allocate for preorders, not including the amount you plan to sell onsite. This number updates as orders are fulfilled."
      handleChange={handleChange}
      inputValue={value}
    />
  );
};

const NewProduct = ({ editable, navigate, location: { state }, productId }) => {
  const { isMobile } = useResponsive();
  const { vendor, productGroupTags, unitTags, productTags, refetchVendor } =
    React.useContext(DataContext);
  const [originalProduct, setOriginalProduct] = React.useState();
  const { showToast } = React.useContext(ToastContext);
  const { control, watch, handleSubmit, setValue, getValues, reset } =
    useForm();
  const watchUnit = useWatch({
    control,
    name: "PriceUnitID",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const selectedUnit = React.useMemo(() => {
    const tag = unitTags.find((tag) => tag.TagID === watchUnit);
    return tag ? tag.TagText : "";
  }, [watchUnit, unitTags]);

  React.useEffect(() => {
    (async () => {
      // Load Product object from backend (ie. url was directly visited) or from router state
      if (editable) {
        let product;
        if (state && state.product) {
          product = state.product;
        } else {
          product = (await getItems([productId])).body.results[productId];
        }
        setOriginalProduct(product);
        const formattedPriceMap = {};
        Object.keys(product.PriceMap).forEach((key) => {
          formattedPriceMap[key] = formatDollars(product.PriceMap[key] / 100);
        });
        reset({
          ...product,
          Tax: product.Tax * 100,
          orderOption: product.AllowFractional ? "no" : "yes",
          pricingAndAvailability: product.MarketArray.reduce((curr, market) => {
            curr[market] = {
              price: formattedPriceMap[market],
              stockToAllocate: product.StockedAmountMap[market],
            };
            return curr;
          }, {}),
        });
      }
    })();
  }, [reset, editable, state, productId]);

  const onSubmit = (data) => {
    // Computed fields
    data.Tax = parseFloat((data.Tax / 100).toFixed(4));
    data.StockedAmountMap = Object.keys(data.pricingAndAvailability).reduce(
      (curr, key) => {
        curr[key] = data.pricingAndAvailability[key].stockToAllocate;
        return curr;
      },
      {}
    );
    data.PriceMap = Object.keys(data.pricingAndAvailability).reduce(
      (curr, key) => {
        curr[key] = Math.round(
          parseFloat(data.pricingAndAvailability[key].price.slice(1)) * 100
        );
        return curr;
      },
      {}
    );
    data.IsAvailable = Object.keys(data.StockedAmountMap).length > 0;
    data.MarketArray = Object.keys(data.StockedAmountMap);
    delete data.pricingAndAvailability;
    delete data.orderOption;
    if (!editable) {
      // Create Product
      data.SellerID = vendor.SellerID;
      data.VendorID = vendor.VendorID;
      data.AvailableAmountMap = data.StockedAmountMap;
      data.NumReviews = 0;
      data.NumStars = 0;
    }
    // Upload/remove images
    const imagesToDelete = [];
    const imagesToUpload = {};
    if (typeof data.CoverImage !== "string") {
      if (
        editable &&
        originalProduct.CoverImage &&
        originalProduct.CoverImage.length > 0
      )
        imagesToDelete.push(originalProduct.CoverImage);
      imagesToUpload.cover = data.CoverImage;
    }
    // Calculate removed images (for Photos field)
    if (editable) {
      imagesToDelete.push(
        ...originalProduct.ImageArray.filter(
          (url) => !data.ImageArray.includes(url)
        )
      );
    }
    // Calculate new images to upload (for Photos field)
    const newPhotos = data.ImageArray.filter((obj) => typeof obj !== "string");
    if (newPhotos.length > 0) {
      imagesToUpload.images = newPhotos;
    }
    delete data.ImageArray;
    delete data.CoverImage;
    (editable ? updateProduct(data) : createProduct(data))
      .then((response) => {
        if (response.ok) {
          return deleteProductImages(imagesToDelete).then(() => {
            showToast({
              message: "Uploading images...",
              type: "success",
            });
            return uploadProductImages(
              response.body.product.ProductID,
              imagesToUpload
            );
          });
        } else {
          throw new Error(response.body.message);
        }
      })
      .then(() => {
        refetchVendor();
        showToast({
          message: `Product ${editable ? "updated" : "created"}!`,
          type: "success",
        });
        navigate("/products");
      })
      .catch((e) => {
        console.error(e);
        showToast({
          message: `Something went wrong: ${e}`,
          type: "error",
          timeout: 10000,
        });
      });
  };

  const onError = (errors) => {
    console.log(errors);
    showToast({
      message: `Please try again: ${Object.values(errors)
        .map((error) => error.message)
        .join(", ")}`,
      type: "error",
    });
  };

  const handleDelete = () => {
    deleteProduct(watch("ProductID")).then((response) => {
      if (response.ok) {
        refetchVendor();
        setShowDeleteConfirmation(false);
        showToast({
          message:
            "Product deleted. Don't forget to update any rewards that apply to this product!",
          type: "success",
        });
        navigate("/products");
      } else {
        console.error(response.body);
        showToast({
          message: `Something went wrong: ${response.body.message}`,
          type: "error",
        });
      }
    });
  };

  return (
    <PageContainer heading={`${editable ? "Edit" : "New"} product`}>
      <BoxContainer width="1086px">
        <Form.Container>
          <ControlledCoverPhoto
            name="CoverImage"
            control={control}
            editable
            helpText="Upload a cover photo for this product:"
          />
          <Form.Content>
            <Form.InputGroup>
              <Form.Label>Product Name</Form.Label>
              <ControlledInput
                name="Name"
                control={control}
                maxChars={100}
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Category</Form.Label>
              <Form.SpacedRow>
                <ControlledSelect
                  name="GroupID"
                  control={control}
                  placeholder="Group"
                  options={productGroupTags.map((group) => ({
                    label: group.TagText,
                    value: group.TagID,
                  }))}
                  flex="1"
                  rules={{ required: true }}
                  onChange={(val) => {
                    setValue("GroupID", val, { shouldValidate: true });
                    setValue("CategoryID", "");
                  }}
                />
                <ControlledSelect
                  name="CategoryID"
                  control={control}
                  placeholder="Category"
                  options={
                    watch("GroupID")
                      ? productGroupTags
                          .find((group) => group.TagID === watch("GroupID"))
                          .CategoryArray.map((category) => ({
                            label: category.TagText,
                            value: category.TagID,
                          }))
                      : []
                  }
                  flex="1"
                  rules={{ required: true }}
                  disabled={!watch("GroupID")}
                />
              </Form.SpacedRow>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Unit</Form.Label>
              <ControlledSelect
                name="PriceUnitID"
                control={control}
                placeholder="Unit"
                options={unitTags.map((unit) => ({
                  label: unit.TagText,
                  value: unit.TagID,
                }))}
                width={isMobile ? "40%" : "15%"}
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Whole quantities only?</Form.Label>
              <ControlledSelect
                name="orderOption"
                control={control}
                placeholder="Order option"
                options={PRODUCT_ORDER_OPTIONS}
                width={isMobile ? "100%" : "25%"}
                rules={{ required: true }}
                onChange={(val) => {
                  setValue("orderOption", val);
                  setValue("AllowFractional", val === "no");
                }}
              />
              <Space height={10} />
              <InputHelpText styles={{ width: 100 }}>
                If no, customers can order in quantities down to the hundredths
                place.
              </InputHelpText>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Pricing and availability</Form.Label>
              <PricingAndAvailability
                control={control}
                name="pricingAndAvailability"
                unit={selectedUnit}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Tax</Form.Label>
              <Row align="center" gap={15}>
                <ControlledInput
                  name="Tax"
                  control={control}
                  textAlign="right"
                  width={isMobile ? "40%" : "15%"}
                  {...decimalInputProps("Tax", setValue, getValues)}
                  min="1"
                />
                <PointsUnitLabel>%</PointsUnitLabel>
              </Row>
              <Space height={10} />
              <InputHelpText styles={{ width: 100 }}>
                Sales tax to charge the customer (0 if no sales tax)
              </InputHelpText>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Order limit per customer</Form.Label>
              <Row align="center" gap={15}>
                <ControlledInput
                  name="OrderLimit"
                  control={control}
                  rules={{ required: true }}
                  width={isMobile ? "40%" : "15%"}
                  type="number"
                  min="0"
                />
                <PointsUnitLabel>{selectedUnit || "unit"}</PointsUnitLabel>
              </Row>
            </Form.InputGroup>

            <Form.InputGroup>
              <Form.Label>Preorders start</Form.Label>
              <Row align="center" gap={15}>
                <ControlledSelect
                  name="PreorderStart"
                  control={control}
                  options={[5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((val) => ({
                    label: val,
                    value: val,
                  }))}
                  width="150px"
                  rules={{ required: true }}
                />
                <Form.Text>days before market date (at 12am)</Form.Text>
              </Row>
            </Form.InputGroup>
            <Space width={40} />

            <Form.InputGroup>
              <Form.Label>Preorders end</Form.Label>
              <Row align="center">
                <ControlledSelect
                  name="PreorderEnd"
                  control={control}
                  options={[1, 2, 3].map((val) => ({
                    label: val,
                    value: val,
                  }))}
                  width="150px"
                  rules={{ required: true }}
                />
                <Form.Text>days before market date (at 8pm)</Form.Text>
              </Row>
            </Form.InputGroup>

            <Form.InputGroup>
              <Form.Label>Loyalty points</Form.Label>
              <Row>
                <Column flex={3}>
                  <Row align="center" gap={15}>
                    <ControlledInput
                      name="RewardPoints"
                      placeholder={0}
                      control={control}
                      type="number"
                      min="0"
                      step="1"
                      width="100px"
                      rules={{ required: true }}
                    />
                    <PointsUnitLabel>
                      per {selectedUnit || "unit"}
                    </PointsUnitLabel>
                  </Row>
                  <InputHelpText styles={{ width: 100 }}>
                    Optional - encourage customer loyalty by offering your own
                    reward points program! Click{" "}
                    <HelpTextLink href="/help">here</HelpTextLink> for more
                    information.
                  </InputHelpText>
                </Column>
                <Column flex={2} />
              </Row>
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Tagline</Form.Label>
              <ControlledInput
                name="Tagline"
                control={control}
                numLines={2}
                maxChars={200}
                helpText="Optional - the tagline will be displayed in bold above the rest of your description."
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Description</Form.Label>
              <ControlledInput
                name="Description"
                control={control}
                numLines={5}
                maxChars={1000}
                rules={{ required: true }}
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <ControlledPhotos
                control={control}
                name="ImageArray"
                defaultValue={[]}
                editable
              />
            </Form.InputGroup>
            <Form.InputGroup>
              <Form.Label>Tags</Form.Label>
              <ControlledTags
                control={control}
                name="TagArray"
                tags={productTags}
                defaultValue={[]}
              />
            </Form.InputGroup>
            <Form.Actions>
              <Row justify="space-between" flex={1}>
                {editable ? (
                  <>
                    <Button
                      color={Colors.gray}
                      onClick={() => setShowDeleteConfirmation(true)}
                    >
                      delete product
                    </Button>
                    <Modal
                      title="Delete product?"
                      visible={showDeleteConfirmation}
                      setVisible={setShowDeleteConfirmation}
                      buttons={[
                        {
                          children: "cancel",
                          color: Colors.gray,
                          onClick: () => setShowDeleteConfirmation(false),
                        },
                        {
                          children: "delete",
                          onClick: handleDelete,
                        },
                      ]}
                    >
                      Would you like to delete {watch("Name")}? This action
                      cannot be undone!
                    </Modal>
                  </>
                ) : (
                  <div />
                )}
                <Row>
                  <Button color={Colors.gray} onClick={() => navigate("../..")}>
                    cancel
                  </Button>
                  <Space width={25} />
                  <Button onClick={handleSubmit(onSubmit, onError)}>
                    save
                  </Button>
                </Row>
              </Row>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default NewProduct;
