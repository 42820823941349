import React from "react";
import CognitoClient from "utils/CognitoClient";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const [cognitoUser, setCognitoUser] = React.useState();

  const loadUser = React.useCallback(async () => {
    try {
      const user = await CognitoClient.getCognitoUser();
      setCognitoUser(user);
    } catch (error) {
      setCognitoUser({});
    }
  }, []);

  const signOut = React.useCallback(async () => {
    await CognitoClient.signOut();
    loadUser();
  }, [loadUser]);

  React.useEffect(() => {
    if (!cognitoUser) loadUser();
  }, [cognitoUser, loadUser]);

  const value = React.useMemo(
    () => ({
      signedIn: cognitoUser && "attributes" in cognitoUser,
      cognitoUser,
      loadUser,
      signOut,
    }),
    [cognitoUser, loadUser, signOut]
  );

  // Loading user state
  if (cognitoUser === undefined) return null;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
