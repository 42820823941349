import PageContainer from "../components/templates/PageContainer";
import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Space from "../components/atoms/Space";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { stripeAccountVerify } from "services";
import { DataContext } from "providers/DataProvider";
import { ToastContext } from "providers/ToastProvider";

const ConfirmStripe = ({ navigate }) => {
  const { refetchVendor } = React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);
  const [failed, setFailed] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const sendStripeVerify = React.useCallback(async () => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      const { ok, body } = await stripeAccountVerify();
      if (ok) {
        await refetchVendor();
        navigate("/");
      } else {
        showToast({
          message: `Something went wrong: ${body.message}`,
          type: "error",
        });
        setFailed(true);
      }
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  }, [refetchVendor, showToast, navigate, disabled]);

  React.useEffect(() => {
    sendStripeVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer heading="Stripe account verification" logo={false}>
      <BoxContainer width="1086px">
        <Form.Container>
          <Form.Content>
            {failed === false ? (
              <Form.InfoText>Checking, please wait...</Form.InfoText>
            ) : (
              <>
                <Form.InfoText>
                  We failed to verify that your linked Stripe account is ready
                  to accept payments.
                </Form.InfoText>
                <Form.InfoText>
                  It takes a few minutes for Stripe to process account
                  completion, so if you already completed your Stripe account,
                  check again in a bit. While you wait, you can edit your vendor
                  profile.
                </Form.InfoText>
                <Form.DenseActions>
                  <Button onClick={() => navigate("/me")}>
                    edit vendor profile
                  </Button>
                  <Space width={20} />
                  <Button onClick={sendStripeVerify}>
                    re-check Stripe account
                  </Button>
                </Form.DenseActions>
                <Space height={20} />
                <Form.InfoText>
                  If you aren't sure if you've finished setting up your Stripe
                  account, you can go back to the Stripe setup page to see if
                  there are any further steps you need to take. Don't worry, any
                  progress you make is saved.
                </Form.InfoText>
                <Form.DenseActions>
                  <Button onClick={() => navigate("/stripe-setup")}>
                    go back to Stripe setup
                  </Button>
                </Form.DenseActions>
              </>
            )}
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default ConfirmStripe;
