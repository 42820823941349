import React from "react";
import Table from "../components/atoms/Table";
import PageContainer from "../components/templates/PageContainer";
import Button from "../components/atoms/Button";
import Checkbox from "../components/atoms/Checkbox";
import Space from "../components/atoms/Space";
import Row from "../components/atoms/Row";
import Input from "../components/atoms/Input";
import { DataContext } from "providers/DataProvider";
import { ToastContext } from "providers/ToastProvider";
import Form from "components/molecules/Form";
import { Colors } from "components/atoms/Theme";
import BoxContainer from "components/atoms/BoxContainer";
import { updateProduct } from "services";
import { resolveUnitLabel } from "utils/helpers";

const AmountInput = ({
  row: {
    original: { ProductID, PriceUnitID },
  },
  handleAmountChange,
  inputValue,
}) => {
  const { unitTags } = React.useContext(DataContext);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    setValue(ProductID in inputValue ? inputValue[ProductID] : "");
  }, [inputValue, ProductID]);

  return (
    <>
      <Row align="center">
        <Input
          variant="compact"
          width="60%"
          type="number"
          min="0"
          step="1"
          value={value}
          disabled={!(ProductID in inputValue)}
          onChange={setValue}
          onBlur={({ target: { value } }) => {
            handleAmountChange(ProductID, value);
          }}
        />
        <Space width={10} />
        {resolveUnitLabel(unitTags, PriceUnitID)}
      </Row>
      <Space height={3} />
    </>
  );
};

const EditMarket = ({ navigate, marketId }) => {
  const { vendor, refetchVendor } = React.useContext(DataContext);
  const { showToast } = React.useContext(ToastContext);
  const [productAmounts, setProductAmounts] = React.useState({});
  const market = vendor.MarketArray.find(
    (market) => market.MarketID === marketId
  );

  const handleReset = React.useCallback(() => {
    const amounts = vendor.ProductArray.reduce((amounts, product) => {
      if (marketId in product.StockedAmountMap)
        amounts[product.ProductID] = product.StockedAmountMap[marketId];
      return amounts;
    }, {});
    setProductAmounts(amounts);
  }, [marketId, vendor.ProductArray]);

  React.useEffect(handleReset, [handleReset]);

  const products = React.useMemo(
    () =>
      vendor.ProductArray.map(({ ProductID, Name, PriceUnitID }) => ({
        ProductID,
        Name,
        PriceUnitID,
      })),
    [vendor.ProductArray]
  );
  const allProducts = products.map((product) => product.ProductID);

  const allProductsSelected = React.useMemo(
    () => Object.keys(productAmounts).length === allProducts.length,
    [productAmounts, allProducts.length]
  );

  const columns = React.useMemo(() => {
    let newValue = { ...productAmounts };

    const handleSelect = (product) => {
      if (product in productAmounts) {
        delete newValue[product];
      } else {
        newValue[product] = 0;
      }
      setProductAmounts(newValue);
    };

    const handleSelectAll = (val) => {
      if (val)
        setProductAmounts(
          allProducts.reduce((acc, product) => {
            acc[product] = 0;
            return acc;
          }, {})
        );
      else setProductAmounts({});
    };

    return [
      {
        Header: "Product name",
        accessor: "Name",
        columnWidth: "50%",
      },
      {
        Header: () => (
          <Row align="center">
            <Checkbox value={allProductsSelected} onChange={handleSelectAll} />
            <Space width={12} />
            In stock
          </Row>
        ),
        Cell: ({
          row: {
            original: { ProductID },
          },
        }) => (
          <Row align="center">
            <Checkbox
              value={ProductID in productAmounts}
              onChange={() => handleSelect(ProductID)}
            />
            <Space width={12} />
            Available
          </Row>
        ),
        id: "available",
        columnWidth: "25%",
        disableSortBy: true,
      },
      {
        Header: () => <Row align="center">Stock to allocate</Row>,
        Cell: AmountInput,
        id: "amount",
        columnWidth: "20%",
        disableSortBy: true,
      },
    ];
  }, [productAmounts, allProducts, setProductAmounts, allProductsSelected]);

  const handleAmountChange = (product, stock) => {
    let newValue = { ...productAmounts };
    newValue[product] = parseInt(stock);
    setProductAmounts(newValue);
  };

  const handleSave = () => {
    const promises = [];
    vendor.ProductArray.forEach((product) => {
      if (product.ProductID in productAmounts) {
        const newAmount = productAmounts[product.ProductID];
        if (newAmount !== product.StockedAmountMap[marketId]) {
          product.IsAvailable = true;
          product.StockedAmountMap[marketId] = newAmount;
        }
      } else {
        product.IsAvailable = false;
        delete product.StockedAmountMap[marketId];
      }
      product.MarketArray = Object.keys(product.StockedAmountMap);

      const promise = new Promise((resolve, reject) => {
        updateProduct(product).then((response) => {
          if (response.ok) resolve(response.ok);
          else reject(response.body);
        });
      });
      promises.push(promise);
    });
    Promise.all(promises)
      .then((result) => {
        if (result.every((x) => x)) {
          refetchVendor();
          showToast({
            message: "Availability saved.",
            type: "success",
          });
          navigate("/markets");
        }
      })
      .catch((e) => {
        console.error(e);
        showToast({
          message: `Something went wrong: ${e.message}`,
          type: "error",
          timeout: 10000,
        });
      });
  };

  return (
    <PageContainer heading={"Edit market availability"}>
      <BoxContainer width="1086px">
        <Form.Container>
          <Form.Content>
            <Form.InfoText>
              Edit availability for {market.Name} ({market.Location})
            </Form.InfoText>
            <Table
              columns={columns}
              data={products}
              searchable={false}
              width="95%"
              helpText="Select which products are available and set the amount of this product that you want to allocate for preorders, not including the amount you plan to sell onsite. This number updates as orders are fulfilled. Don't forget to update your stock regularly."
              handleAmountChange={handleAmountChange}
              inputValue={productAmounts}
            />
            <Form.Actions>
              <Row justify="space-between" flex={1}>
                <div />
                <Row gap={10}>
                  <Button onClick={handleSave}>save</Button>
                  <Button color={Colors.gray} onClick={() => navigate("../..")}>
                    cancel
                  </Button>
                  <Button color={Colors.gray} onClick={handleReset}>
                    reset
                  </Button>
                </Row>
              </Row>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </PageContainer>
  );
};

export default EditMarket;
