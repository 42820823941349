import styled from "styled-components";
import Column from "../atoms/Column";
import { Colors, Typography } from "../atoms/Theme";
import VendorLogo from "./VendorLogo";

const TopbarContainer = styled.div`
  min-height: 106px;
  background: white;
  padding-left: 70px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  margin-left: -30px;
`;

const TextContainer = styled(Column)`
  height: 100%;
  justify-content: center;
`;

const Heading = styled.span`
  ${Typography.heading}
`;

const Subheading = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${Colors.gray};
`;

const Topbar = ({ heading, subheading, logo }) => {
  return (
    <TopbarContainer>
      <TextContainer>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
      </TextContainer>
      {logo && (
        <Column>
          <VendorLogo />
        </Column>
      )}
    </TopbarContainer>
  );
};

export default Topbar;
